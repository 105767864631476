import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Grid, Link, Box, Typography, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
import { PATH_PAGE } from '../../routes/paths'
// components
import SocialsButton from '../../components/SocialsButton'
import Image from 'src/components/Image'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';


// ----------------------------------------------------------------------

const LINKS = [
  {
    children: [
      { name: 'Contact Us', href: PATH_PAGE.contact_us, target: true },
      { name: 'Help', href: PATH_PAGE.help, target: true },
      { name: 'Blog', href: PATH_PAGE.blog, target: true }
    ]
  },
  {
    children: [
      { name: 'Terms and Conditions', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.policy },
      { name: 'info@omodore.com', href: 'mailto:info@omodore.com' }
    ]
  },
]

const RootStyle = styled('div')(({ theme }) => ({
  marginTop: '100px',
  backgroundColor: '#000',
  color: '#fff',
  zIndex: '10000',
  paddingBottom: '20px'
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <RootStyle>
      <Box sx={{ pt: 2, px: 5 }}>
        {
          isMobile ? <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Link href="/auth/login" variant="body2" sx={{
                pr: { md: 5 }, display: 'flex', alignItems: 'center', color: '#fff', textDecoration: 'none', '&:hover': {
                  textDecoration: 'none',
                },
              }}>
                <Image src="/logo/3.png" sx={{ marginLeft: '-10px', width: '40px' }} />
                <Typography sx={{ fontSize: '30px' }}>omodore</Typography>
              </Link>

            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="center">
                {LINKS.map((list) => (
                  <Stack key={list.headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {list.headline}
                    </Typography>

                    {list.children.map((link) => (
                      link.name !== 'info@omodore.com' &&
                      <Link href={link.href} key={link.name} target={link.target ? '_self' : '_blank'} color="inherit" variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1, textAlign: 'center', justifyContent: 'center' }}>
                        {link?.icon}
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} sx={{ justifyContent: 'center', display: 'flex' }}>
              <Stack sx={{ mt: { xs: 5, md: 0 }, mb: { xs: 5, md: 0 }, ml: '-10px' }}>
                <Link href="mailto:info@omodore.com" sx={{ display: 'flex', justifyContent: 'center', textDecoration: 'none', color: '#fff' }}>info@omodore.com</Link>
                <SocialsButton sx={{ color: "#fff" }} />
              </Stack>
            </Grid>
            <Typography
              component="p"
              variant="body2"
              sx={{
                pb: 5,
                fontSize: 13,
                textAlign: 'center'
              }}
            >
              <Link href='https://www.aitechsolutionsltd.com/' target='_blank' sx={{
                textDecoration: 'none', color: 'white', '&:hover': {
                  textDecoration: 'none',
                },
              }}>
                © Ai Tech Solutions Limited
              </Link> {new Date().getFullYear()}. All Rights Reserved.
            </Typography>
          </Grid>
            :
            <Grid container justifyContent={{ sm: 'space-between' }} spacing={2}>
              <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Link href="/auth/login" variant="body2" sx={{
                  pr: { md: 5 }, display: 'flex', alignItems: 'center', color: '#fff', textDecoration: 'none', '&:hover': {
                    textDecoration: 'none',
                  },
                }}>
                  <Image src="/logo/3.png" sx={{ marginLeft: '-10px', width: '40px' }} />
                  <Typography sx={{ fontSize: '20px' }}>omodore</Typography>
                </Link>
                <Typography
                  component="p"
                  variant="body2"
                  sx={{
                    fontSize: 13,
                    mt: 'auto',
                    mb: '0'
                  }}
                >
                  <Link href='https://www.aitechsolutionsltd.com/' target='_blank' sx={{
                    textDecoration: 'none', color: 'white', '&:hover': {
                      textDecoration: 'none',
                    },
                  }}>
                    © Ai Tech Solutions Limited
                  </Link> {new Date().getFullYear()}. All Rights Reserved.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={10} direction={{ xs: 'column', md: 'row' }} justifyContent="center">
                  {LINKS.map((list) => (
                    <Stack key={list.headline} spacing={2}>
                      <Typography component="p" variant="overline">
                        {list.headline}
                      </Typography>

                      {list.children.map((link) => (
                        <Link href={link.href} key={link.name} target={link.target ? '_self' : '_blank'} color="inherit" variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {link?.icon}
                          {link.name}
                        </Link>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} sx={{ justifyContent: { xs: 'flex-start', md: 'flex-end' }, display: 'flex' }}>
                <Stack sx={{ mt: { xs: 5, md: 'auto' }, mb: { xs: 5, md: 0 }, ml: '-10px' }}>
                  <SocialsButton sx={{ color: "#fff" }} />
                </Stack>
              </Grid>
            </Grid>
        }
      </Box>
    </RootStyle >
  )
}
