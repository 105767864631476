import * as React from 'react';
import { Typography, Box, Stack, Button, FormControl, FormHelperText } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    overflowY: 'scroll',
    maxHeight: '500px',
    p: 4,
};


export default function NewAgentModal({ openNewAgentModal, setOpenNewAgentModal, setOpenEditingModeModal, agentInfo, setAgentInfo }) {
    const [errors, setErrors] = React.useState({});
    const handleClose = () => setOpenNewAgentModal(false);

    const validateFields = () => {
        let tempErrors = {};
        if (!agentInfo.salespersonName) tempErrors.salespersonName = "Salesperson name is required";
        if (!agentInfo.role) tempErrors.role = "Salesperson Role is required";
        if (!agentInfo.companyName) tempErrors.companyName = "Company Name is required";
        if (!agentInfo.aboutCompany) tempErrors.aboutCompany = "About Company is required";
        if (!agentInfo.companyFocus) tempErrors.companyFocus = "Company Focus is required";
        if (!agentInfo.interviewTopic) tempErrors.interviewTopic = "Interview Topic is required";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    const handleCreateAgent = () => {
        if (validateFields()) {
            setErrors({});
            handleClose();
            setOpenEditingModeModal(true);
        }
    }

    return (
        <Modal
            open={openNewAgentModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    Create a New Agent
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.salespersonName}>
                            <label htmlFor='salesperson-name'>Salesperson Name: </label>
                            <TextField
                                required={true}
                                id="salesperson-name"
                                label="Enter Salesperson Name*"
                                multiline
                                maxRows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, salespersonName: e.target.value })
                                    setErrors({ ...errors, salespersonName: "" });
                                }}
                                error={!!errors.salespersonName}
                            />
                            {errors.salespersonName && (
                                <FormHelperText>{errors.salespersonName}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.role}>
                            <label htmlFor='salesperson-role'>Role: </label>
                            <TextField
                                required={true}
                                id="salesperson-role"
                                label="Enter Role*"
                                multiline
                                maxRows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, role: e.target.value });
                                    setErrors({ ...errors, role: "" });
                                }}
                                error={!!errors.role}
                            />
                            {errors.role && (
                                <FormHelperText>{errors.role}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.companyName}>
                            <label htmlFor='salesperson-company'>Company Name: </label>
                            <TextField
                                required={true}
                                id="salesperson-company"
                                label="Enter Company Name*"
                                multiline
                                maxRows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, companyName: e.target.value });
                                    setErrors({ ...errors, companyName: "" });
                                }}
                                error={!!errors.companyName}
                            />
                            {errors.companyName && (
                                <FormHelperText>{errors.companyName}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.aboutCompany}>
                            <label htmlFor='about-company'>About Company: </label>
                            <TextField
                                required={true}
                                id="about-company"
                                label="Enter Information About the Company*"
                                multiline
                                rows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, aboutCompany: e.target.value });
                                    setErrors({ ...errors, aboutCompany: "" });
                                }}
                                error={!!errors.aboutCompany}
                            />
                            {errors.aboutCompany && (
                                <FormHelperText>{errors.aboutCompany}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.companyFocus}>
                            <label htmlFor='company-focus'>Company Focus: </label>
                            <TextField
                                required={true}
                                id="company-focus"
                                label="Enter what company mainly focuses on for its business*"
                                multiline
                                rows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, companyFocus: e.target.value });
                                    setErrors({ ...errors, companyFocus: "" });
                                }}
                                error={!!errors.companyFocus}
                            />
                            {errors.companyFocus && (
                                <FormHelperText>{errors.companyFocus}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <FormControl fullWidth error={!!errors.interviewTopic}>
                            <label htmlFor='interview-topic'>Interview Topic: </label>
                            <TextField
                                required={true}
                                id="interview-topic"
                                label="Enter Interview Topic*"
                                multiline
                                rows={4}
                                sx={{ mt: 1 }}
                                onChange={e => {
                                    setAgentInfo({ ...agentInfo, interviewTopic: e.target.value })
                                    setErrors({ ...errors, interviewTopic: "" });
                                }}
                                error={!!errors.interviewTopic}
                            />
                            {errors.interviewTopic && (
                                <FormHelperText>{errors.interviewTopic}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            display={'flex'}
                            sx={{ ml: 'auto', mr: 0 }}
                            onClick={handleCreateAgent}
                        >
                            Create Agent
                        </Button>
                    </Stack>
                </Typography>
            </Box>
        </Modal >
    )
}