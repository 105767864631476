import * as React from "react";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSettings from "src/hooks/useSettings";
// routes

// components
import Page from "src/components/Page";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import axios from "src/utils/axios";
import { createAgent } from "src/redux/slices/agent";
import AgentEmpty from "src/sections/agents/empty";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { TrendingDown } from "@mui/icons-material";
import BarChartIcon from "@mui/icons-material/BarChart";
import RecommendIcon from "@mui/icons-material/Recommend";

import NewAgentModal from "src/components/modals/agent/NewAgentModal";
import EditingModeModal from "src/components/modals/agent/EditingModeModal";
import ScriptModal from "src/components/modals/agent/ScriptModal";
import ChartModal from "src/components/modals/ChartModal";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "edit_mode", label: "Edit Mode", minWidth: 100 },
  { id: "script_type", label: "Script Type", minWidth: 100 },
  { id: "last_modified", label: "Last Modified", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 150 },
];

const sections = ["companyName", "editMode", "script", "updated_at"];

export default function Agents() {
  const { themeStretch } = useSettings();
  const [agentPage, setAgentPage] = React.useState(0);
  const [rowsPerAgentPage, setRowsPerAgentPage] = React.useState(10);
  const [openNewAgentModal, setOpenNewAgentModal] = React.useState(false);
  const [openEditingModeModal, setOpenEditingModeModal] = React.useState(false);
  const [openScriptModal, setOpenScriptModal] = React.useState(false);
  const [agents, setAgents] = React.useState([]);
  const [overAll, setOverAll] = React.useState(0);
  const [showChartModal, setShowChartModal] = React.useState(false);
  const [overAllUsageCnt, setOverAllUsageCnt] = React.useState(0);
  const [successCnt, setSuccessCnt] = React.useState(0);
  const [overAllAgents, setOverAllAgents] = React.useState([]);
  const [overAllUsage, setOverAllUsage] = React.useState([]);
  const [successResult, setSuccessResult] = React.useState([]);
  const [chartNumber, setChartNumber] = React.useState(0);
  const [title, setTitle] = React.useState("");

  const [agentInfo, setAgentInfo] = React.useState({
    userId: jwtDecode(localStorage.getItem("accessToken")).user_id,
    editMode: "standard",
    script: "magic",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get("/api/v1/agents/user/" + jwtDecode(localStorage.accessToken).user_id)
      .then((result) => {
        setAgents([...result.data.agents]);
        setOverAll(result.data.overAll);
        setOverAllUsageCnt(result.data.overallUsage ? result.data.overallUsage : 0);
        setSuccessCnt(result.data.overallSuccess);
      })
      .catch((err) => {
        console.log("error is ", err);
      });

    axios
      .get("/api/v1/agents/overall/")
      .then((res) => {
        setOverAllAgents([...res.data]);
      })
      .catch((err) => {
        console.log("Getting Overall Agents Error is ", err);
      });

    axios
      .get("/api/v1/agents/overall_usage/")
      .then((res) => {
        setOverAllUsage([...res.data]);
      })
      .catch((err) => {
        console.log("Getting Overall Usage Error is ", err);
      });
  }, []);

  const check_rising = (data) => {
    const total = data.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue);
    }, 0);

    const cur_month = data[Number(new Date().getMonth())];
    const mean = (total - cur_month) / Number(new Date().getMonth());

    return cur_month > mean;
  };

  const calc_rising = (data) => {
    const total = data.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue);
    }, 0);

    const cur_month = data[Number(new Date().getMonth())];
    const mean = (total - cur_month) / Number(new Date().getMonth());

    return ((Math.abs(cur_month - mean) / mean) * 100).toFixed(2) + "%";
  };

  const OverAllAgentCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Overall Agent
          </Typography>
          <Typography variant="h5" component="div">
            {overAll}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <BarChartIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        {check_rising(overAllAgents) ? (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(57, 181, 74, .2)",
              color: "#39B54A",
            }}
          >
            <TrendingUpIcon color="text.secondary" />
          </Stack>
        ) : (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(181, 57, 57, .2)",
              color: "#B53939",
            }}
          >
            <TrendingDown color="text.secondary" />
          </Stack>
        )}
        <Stack>{calc_rising(overAllAgents)}</Stack>
        <Button
          sx={{ marginLeft: "auto !important", marginRight: "0" }}
          onClick={(e) => handleOverallAgents()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );
  const OverAllUsageCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Overall Usage
          </Typography>
          <Typography variant="h5" component="div">
            {overAllUsageCnt}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <BarChartIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        {check_rising(overAllUsage) ? (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(57, 181, 74, .2)",
              color: "#39B54A",
            }}
          >
            <TrendingUpIcon color="text.secondary" />
          </Stack>
        ) : (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(181, 57, 57, .2)",
              color: "#B53939",
            }}
          >
            <TrendingDown color="text.secondary" />
          </Stack>
        )}
        <Stack>{calc_rising(overAllUsage)}</Stack>
        <Button
          sx={{ marginLeft: "auto !important", marginRight: "0" }}
          onClick={(e) => handleOverAllUsage()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );
  const SuccessfulResultCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Successful Result
          </Typography>
          <Typography variant="h5" component="div">
            {(overAllUsageCnt
              ? ((successCnt / overAllUsageCnt) * 100).toFixed(2)
              : "0.00") + "%"}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <RecommendIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        <Button
          sx={{ marginLeft: "auto", marginRight: "0" }}
          onClick={(e) => handleSuccessChart()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );

  const handleOpenNewAgentModal = () => setOpenNewAgentModal(true);

  const handleChangeAgentPage = (event, newPage) => {
    setAgentPage(newPage);
  };

  const handleChangeRowsPerAgentPage = (event) => {
    setRowsPerAgentPage(event.target.value);
    setAgentPage(0);
  };

  const handleEditAgentInfo = (agentData) => {
    dispatch(createAgent(agentData));

    navigate("/agents/edit/" + agentData.id);
  };

  const handleDeleteAgentInfo = (agentData) => {
    const prevAgents = agents;
    axios
      .delete(`/api/v1/agents/delete/${agentData.id}`)
      .then((response) => {
        setAgents(() => {
          return prevAgents.filter((item) => item.id !== agentData.id);
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const handleOverallAgents = () => {
    setChartNumber(0);
    setTitle("Overview of Over All Agents");
    setShowChartModal(true);
  };

  const handleOverAllUsage = () => {
    setChartNumber(1);
    setTitle("Overview of Over All Usage of Agents");
    setShowChartModal(true);
  };

  const handleSuccessChart = () => {
    setChartNumber(2);
    setTitle("Overview of Success Result");
    setSuccessResult([
      { id: 0, value: successCnt, label: "Success", color: "green" },
      {
        id: 1,
        value: overAllUsageCnt - successCnt,
        label: "Failed",
        color: "red",
      },
    ]);
    setShowChartModal(true);
  };

  return (
    <Page title="Agents" height={"100%"}>
      <Box
        display={"flex"}
        width={"100%"}
        gap={4}
        justifyContent={"center"}
        sx={{ px: 3, flexWrap: "wrap", mt: 5 }}
      >
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {OverAllAgentCard}
        </Card>
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {OverAllUsageCard}
        </Card>
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {SuccessfulResultCard}
        </Card>
      </Box>
      {agents.length ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Stack direction="row">
            <Button
              variant="contained"
              display={"flex"}
              sx={{ ml: "auto", mr: 0, my: 3 }}
              onClick={handleOpenNewAgentModal}
            >
              New Agent
            </Button>
          </Stack>

          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid rgba(65,65,65, 0.13)",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agents.length
                    ? agents
                        .slice(
                          agentPage * rowsPerAgentPage,
                          agentPage * rowsPerAgentPage + rowsPerAgentPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow key={row.companyName + index}>
                              {sections.map((column, index1) => {
                                const value = row[column];
                                return (
                                  <TableCell
                                    key={column + index1}
                                    align={column.align}
                                  >
                                    {column !== "updated_at"
                                      ? value
                                      : new Date(value).toLocaleString(
                                          "en-GB",
                                          {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          }
                                        )}
                                  </TableCell>
                                );
                              })}
                              <TableCell>
                                <IconButton
                                  aria-label="edit"
                                  onClick={(e) => handleEditAgentInfo(row)}
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={(e) => handleDeleteAgentInfo(row)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={agents.length}
              rowsPerPage={rowsPerAgentPage}
              page={agentPage}
              onPageChange={handleChangeAgentPage}
              onRowsPerPageChange={handleChangeRowsPerAgentPage}
            />
          </Paper>
        </Container>
      ) : (
        <AgentEmpty setOpenNewAgentModal={setOpenNewAgentModal} />
      )}
      <NewAgentModal
        openNewAgentModal={openNewAgentModal}
        setOpenNewAgentModal={setOpenNewAgentModal}
        setOpenEditingModeModal={setOpenEditingModeModal}
        agentInfo={agentInfo}
        setAgentInfo={setAgentInfo}
      />

      <EditingModeModal
        openEditingModeModal={openEditingModeModal}
        setOpenEditingModeModal={setOpenEditingModeModal}
        setOpenScriptModal={setOpenScriptModal}
        agentInfo={agentInfo}
        setAgentInfo={setAgentInfo}
      />

      <ScriptModal
        openScriptModal={openScriptModal}
        setOpenScriptModal={setOpenScriptModal}
        agentInfo={agentInfo}
        setAgentInfo={setAgentInfo}
      />

      <ChartModal
        modalShow={showChartModal}
        setModalShow={setShowChartModal}
        data={
          chartNumber
            ? chartNumber === 1
              ? overAllUsage
              : successResult
            : overAllAgents
        }
        title={title}
        isLegend={chartNumber === 2}
      />
    </Page>
  );
}
