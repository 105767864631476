import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Grid, Stack, Box, Typography, useMediaQuery, styled, Link } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Page from "src/components/Page";
import { SignUpButton } from "./Header";
import 'swiper/swiper-bundle.css';
import Image from 'src/components/Image';

export default function HomePage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Page title="Landing Page">
            <Stack>
                <Stack sx={{
                    backgroundImage: `url('/assets/bg_ellipse.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    alignItems: 'center'
                }}>
                    <Grid container spacing={2} sx={{
                        pt: isMobile ? 5 : 10,
                        pb: isMobile ? 10 : 60,
                        position: 'relative',
                        maxWidth: '1400px',
                    }}>
                        <Grid item xs={12} md={6} order={isMobile ? 2 : 1} sx={{
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pb: isMobile ? 4 : 0
                        }}>
                            <Box
                                component="video"
                                controls
                                loop
                                autoPlay
                                muted
                                src="/assets/videos/omodore.mp4"
                                sx={{
                                    width: '90%',
                                    height: 'auto',
                                    borderRadius: '10px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} order={isMobile ? 1 : 2} sx={{
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: isMobile ? 'flex-start' : 'center',
                            pl: isMobile ? 2 : 5,
                        }}>
                            <Stack spacing={2} sx={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
                                <Typography variant="h2" sx={{ fontSize: isMobile ? '2rem' : '3rem', textAlign: isMobile ? 'center' : 'left' }}>
                                    Transform Your Customer Engagement
                                </Typography>
                                <Typography sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                                    Strengthen your customer interactions with Omodore&apos;s AI-driven solutions, meticulously crafted to suit your business's unique requirements.
                                </Typography>
                                <SignUpButton />
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('/assets/bg_ellipse_1.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    pb: isMobile ? 10 : 30
                }}>
                    <Typography variant="h2" sx={{ fontSize: isMobile ? '2rem' : '3rem' }}>
                        People Who
                    </Typography>
                    <Typography variant="h2" sx={{ color: '#3EB54B', fontSize: isMobile ? '2rem' : '3rem' }}>
                        Trust Us
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mt: 5,
                        pb: isMobile ? 10 : 20
                    }}>
                        <Carousel />
                    </Box>
                </Stack>
                <Stack sx={{ mb: 10 }}>
                    <Image src="assets/bg_ellipse_2.png" alt="background 2" sx={{ mt: isMobile ? '100px' : '-400px', zIndex: '-1' }} />
                    <Stack sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        // mt: isMobile ? '-300px' : '-600px',
                        mt: {
                            xs: '-300px',
                            sm: '-450px',
                            xxl: '-600px',
                        },
                        px: isMobile ? 2 : 0
                    }}>
                        <Typography
                            variant='h2'
                            sx={{
                                fontWeight: '700',
                                textAlign: 'center',
                                mb: isMobile ? 0 : 10,
                                fontSize: isMobile ? '2rem' : '3rem'
                            }}>
                            Apply the power of Chat Marketing to
                            <Typography variant='h2' sx={{ color: '#3EB54B', fontWeight: '700' }}>boost your sales effortlessly</Typography>
                        </Typography>
                        <Introduction />
                    </Stack>
                </Stack>
                <Steps />
            </Stack>
        </Page>
    );
}

function Carousel() {
    const handleChange = () => {
        console.log('Slide Changed.');
    };

    const StyledSwiper = styled(Swiper)`
        width: 80%;
        align-items: center;
        overflow: hidden;
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            margin: 0 5px;
            box-sizing: border-box;
        }
        `;

    return (
        <StyledSwiper
            grabCursor={true}
            effect={"coverflow"}
            pagination={{ clickable: true }}
            centeredSlides={true}
            slidesPerView={3}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
            }}
            coverflowEffect={{
                rotate: 30,
                stretch: 1,
                depth: 200,
                modifier: 1,
                slideShadows: false,
            }}
            onSlideChange={handleChange}
            modules={[EffectCoverflow, Pagination]}
            className={StyledSwiper}
        >
            <SwiperSlide><Image src="/assets/videos/trust1.png" alt="Slide 1" /></SwiperSlide>
            <SwiperSlide><Image src="/assets/videos/trust2.png" alt="Slide 2" /></SwiperSlide>
            <SwiperSlide><Image src="/assets/videos/trust3.png" alt="Slide 3" /></SwiperSlide>
        </StyledSwiper>
    );
}

function Introduction() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }}>
                    <Image src="/assets/images/1.png" alt="Introduction_1" sx={{ width: '400px' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}>Drive Sales with <br />
                            AI-Powered Chat Marketing
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Utilize AI's capabilities to effortlessly
                            drive sales. Engage customers seamlessly
                            and propel your business growth with
                            Omodore&apos;s advanced features.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }} order={isMobile ? 2 : 1}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}>Accelerate <br />
                            Lead Generation
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Connect with customers wherever
                            they are, whenever they're ready.
                            Capture up to 80% of lead contact information automatically to ensure effective follow-up.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }} order={isMobile ? 1 : 2}>
                    <Image src="/assets/images/2.png" alt="Introduction_2" sx={{ width: '400px' }} />
                </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }}>
                    <Image src="/assets/images/3.png" alt="Introduction_3" sx={{ width: '400px' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}> Maximize <br />
                            Conversion Rates
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Ensure no lead slips away and seize every opportunity to reengage interested prospects without relying on costly retargeting campaigns
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }} order={isMobile ? 2 : 1}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}> Instant <br />
                            Response Automation
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Engage instantly with every message, reaction, and mention. Speed up customer interactions to ensure
                            no potential lead goes unnoticed
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }} order={isMobile ? 1 : 2}>
                    <Image src="/assets/images/4.png" alt="Introduction_4" sx={{ width: '400px' }} />
                </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }}>
                    <Image src="/assets/images/5.png" alt="Introduction_5" sx={{ width: '400px' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}>Reclaim Your <br />
                            Time & Reduce Costs
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Automate FAQ responses, streamline communication, and provide timely
                            24/7 replies while reducing
                            operational expenses.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', maxWidth: '1024px' }} spacing={5}>
                <Grid item xs={12} md={6} sx={{ px: '20px !important' }} order={isMobile ? 2 : 1}>
                    <Stack sx={{ maxWidth: '400px' }} spacing={2}>
                        <Typography sx={{ fontWeight: '700', fontSize: '30px' }}>No Tech <br />
                            Experience? No Worries
                        </Typography>
                        <Typography sx={{ fontSize: '20px' }}>
                            Utilize our Ai Assistant or plug-and-play
                            templates to easily set up your first
                            automation. Customize any template
                            or AI-generated conversation
                            effortlessly with just a few clicks.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', pl: '0px !important' }} order={isMobile ? 1 : 2}>
                    <Image src="/assets/images/6.png" alt="Introduction_6" sx={{ width: '400px' }} />
                </Grid>
            </Grid>
        </Stack >
    )
}

function Steps() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack spacing={5} sx={{ alignItems: 'center' }}>
            <Typography variant='h2' sx={{ textAlign: 'center', lineHeight: 1 }}>
                Start Your<br />
                Omodore <span style={{ color: '#3EB54B' }}>Journey</span>
            </Typography>

            <Stack>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        maxWidth: '800px',
                        border: '1px solid #EDEDED',
                        borderRadius: '40px',
                        p: '20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        mb: 3,
                        mx: 2
                    }}
                >
                    <Image
                        src="/assets/images/group1.png"
                        alt="Group_1"
                        sx={{ maxWidth: '200px' }}
                    />
                    <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant='h5'>Step 1</Typography>
                        <Link
                            href="/auth/register"
                            sx={{
                                mb: 2,
                                fontSize: '20px',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }
                            }}
                        >
                            Create a Free Account
                        </Link>
                        <Typography sx={{ fontSize: '18px' }}>
                            Begin exploring Omodore&apos;s powerful features. Easily set up your account to access our full suite of AI-driven tools. Begin your journey with Omodore by creating a free account. Gain immediate access to our comprehensive suite of AI-driven
                            tools designed to enhance your customer engagement.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        maxWidth: '800px',
                        border: '1px solid #EDEDED',
                        borderRadius: '40px',
                        p: '20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        mb: 3,
                        mx: 2
                    }}
                >
                    <Image
                        src="/assets/images/group2.png"
                        alt="Group_2"
                        sx={{ maxWidth: '200px' }}
                    />
                    <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant='h5'>Step 2</Typography>
                        <Link
                            href="/auth/register"
                            sx={{
                                mb: 2,
                                fontSize: '20px',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }
                            }}
                        >
                            Set Up Your Team
                        </Link>
                        <Typography sx={{ fontSize: '18px' }}>
                            Tailor your team&apos;s capabilities to efficiently manage customer interactions. Customize your team&apos;s setup effortlessly with Omodore. Whether you choose to personalize your agents or utilize our ready-to-go configurations, empower your team to deliver exceptional customer service.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        maxWidth: '800px',
                        border: '1px solid #EDEDED',
                        borderRadius: '40px',
                        p: '20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        mb: 3,
                        mx: 2
                    }}
                >
                    <Image
                        src="/assets/images/group3.png"
                        alt="Group_3"
                        sx={{ maxWidth: '200px' }}
                    />
                    <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant='h5'>Step 3</Typography>
                        <Link
                            href="/auth/register"
                            sx={{
                                mb: 2,
                                fontSize: '20px',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }
                            }}
                        >
                            Efficient Training
                        </Link>
                        <Typography sx={{ fontSize: '18px' }}>
                            Equip your team with the necessary skills to handle messages, appointments, and more. Ensure your team is well-prepared to handle customer interactions seamlessly. Train them effectively using Omodore&apos;s resources, empowering them to provide superior service.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    sx={{
                        alignItems: 'center',
                        maxWidth: '800px',
                        border: '1px solid #EDEDED',
                        borderRadius: '40px',
                        p: '20px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        mb: 3,
                        mx: 2
                    }}
                >
                    <Image
                        src="/assets/images/group4.png"
                        alt="Group_4"
                        sx={{ maxWidth: '200px' }}
                    />
                    <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant='h5'>Step 4</Typography>
                        <Link
                            href="/auth/register"
                            sx={{
                                mb: 2,
                                fontSize: '20px',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }
                            }}
                        >
                            Get Started for Free
                        </Link>
                        <Typography sx={{ fontSize: '18px' }}>
                            Get Started for Free: Begin engaging with customers
                            effortlessly using Omodore&apos;s intuitive platform. Dive into Omodore&apos;s user-friendly platform and start engaging with customers effortlessly. Take advantage of our powerful tools to enhance your customer interactions and drive business growth.
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <SignUpButton />
        </Stack>
    )
}
