// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon("ic_campaigns"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_contact"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_recording"),
  terms: getIcon("ic_condition"),
  dashboard: getIcon("ic_create"),
  menuItem: getIcon("ic_menu_item"),
  global: getIcon("ic_global"),
  preferences: getIcon("ic_setting"),
  policy: getIcon("ic_policy"),
  contactUs: getIcon("ic_contact_us"),
  howTo: getIcon('ic_question')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Overview",
    items: [
      {
        title: "create",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        children: [
          { title: "Agent", path: PATH_DASHBOARD.general.agents },
          {
            title: "Knowledge Base",
            path: PATH_DASHBOARD.general.knowledgeBases,
          },
        ],
      },
      {
        title: "contacts",
        path: PATH_DASHBOARD.contact.root,
        icon: ICONS.mail,
        children: [
          { title: "contacts", path: PATH_DASHBOARD.contact.create },
          { title: "Lists", path: PATH_DASHBOARD.contact.lists },
        ],
      },
      {
        title: "recordings",
        path: PATH_DASHBOARD.recordings.root,
        icon: ICONS.analytics,
      },
      {
        title: "how-To",
        path: PATH_DASHBOARD.help.root,
        icon: ICONS.howTo,
      }
    ],
  },
];

export default navConfig;
