import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Link, Stack, Button, Tooltip, IconButton, Box } from '@mui/material';
//
import Iconify from './Iconify';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

// ----------------------------------------------------------------------

SocialsButton.propTypes = {
  initialColor: PropTypes.bool,
  links: PropTypes.objectOf(PropTypes.string),
  simple: PropTypes.bool,
  sx: PropTypes.object,
};

const TikTokIcon = ({ color }) => {
  return (
    <Box sx={{ with: '20px', height: '20px' }}>
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="100%"
        height="100%"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    </Box>
  )
}
const XIcon = ({ color }) => {
  return (
    <Box sx={{ with: '20px', height: '20px' }}>
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M18.364 3.636l-13.728 16.728 1.414 1.414L10.778 17l4.95 5.778h2.828l-6.364-7.414L20.193 1.222 18.364 0h-2.828l-9.193 11.121-4.95-5.778H.707l6.364 7.414L.707 19.192.293 21.678l13.728-16.728z" />
      </svg>
    </Box>
  )
}



export default function SocialsButton({ initialColor = false, simple = true, links = {
  facebook: 'https://www.facebook.com/omodore/',
  instagram: 'https://www.instagram.com/omodore.ai/',
  linkedin: 'https://www.linkedin.com/company/omodore',
  youtube: 'https://www.youtube.com/@Omodore',
  x: 'https://x.com/omodore_ai',
  tiktok: 'https://www.tiktok.com/@omodore_ai'
}, sx, ...other }) {
  const SOCIALS = [
    {
      name: 'FaceBook',
      icon: <FacebookIcon />,
      socialColor: '#1877F2',
      path: links.facebook || '#facebook-link',
    },
    {
      name: 'Instagram',
      icon: <InstagramIcon />,
      socialColor: '#E02D69',
      path: links.instagram || '#instagram-link',
    },
    {
      name: 'Linkedin',
      icon: <LinkedInIcon />,
      socialColor: '#007EBB',
      path: links.linkedin || '#linkedin-link',
    },
    {
      name: 'X',
      icon: <XIcon color={sx?.color ? sx?.color : '#00AAEC'} />,
      socialColor: '#00AAEC',
      path: links.x || '#x-link',
    },
    {
      name: 'Youtube',
      icon: <YouTubeIcon />,
      socialColor: '#00AAEC',
      path: links.youtube || '#youtube-link',
    },
    {
      name: 'Tiktok',
      icon: <TikTokIcon color={sx?.color ? sx?.color : '#00AAEC'} />,
      socialColor: '#00AAEC',
      path: links.tiktok || '#tiktok-link',
    },

  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map((social) => {
        const { name, icon, path, socialColor } = social;
        return simple ? (
          <Link key={name} href={path} target="_blank">
            <Tooltip title={name} placement="top">
              <IconButton
                color="inherit"
                sx={{
                  ...(initialColor && {
                    color: socialColor,
                    '&:hover': {
                      bgcolor: alpha(socialColor, 0.08),
                    },
                  }),
                  ...sx,
                }}
                {...other}
              >
                {icon}
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Button
            key={name}
            href={path}
            target="_blank"
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<Iconify icon={icon} />}
            sx={{
              m: 0.5,
              flexShrink: 0,
              ...(initialColor && {
                color: socialColor,
                borderColor: socialColor,
                '&:hover': {
                  borderColor: socialColor,
                  bgcolor: alpha(socialColor, 0.08),
                },
              }),
              ...sx,
            }}
            {...other}
          >
            {name}
          </Button>
        );
      })}
    </Stack>
  );
}
