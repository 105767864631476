import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookie-consent");
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookie-consent", "accepted", { expires: 365 });
    setVisible(false);
  };

  const handleDecline = () => {
    Cookies.set("cookie-consent", "declined", { expires: 365 });
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        bottom: "0",
        width: "100%",
        padding: {
          xs: "10px",
          sm: "10px 50px 15px",
        },
        backgroundColor: "#ebebeb",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
        borderRadius: "5px",
        zIndex: 100001,
        borderBottom: "10px solid #3EB54B",
      }}
    >
      {/* Box content goes here */}

      <Stack
        sx={{
          flexDirection: "row",
          gap: "30px",
        }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center", fontWeight: "600" }}
        >
          We use cookies to ensure you get the best experience on our website.
        </Typography>
        <LoadingButton
          onClick={handleAccept}
          sx={{
            marginLeft: "10px",
            padding: "5px 20px",
            border: "none",
            color: "#fff",
            backgroundColor: "#3EB54B",
            "&:hover": {
              backgroundColor: "#007B55",
            },
            borderRadius: "10px",
            cursor: "pointer",
          }}
          variant="contained"
        >
          Accept
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CookieConsent;
