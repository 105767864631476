import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'src/redux/slices/user';

export default function KnowledgeBaseEmpty({ setOpenNewKnowledgeBaseModal }) {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={3}
            alignItems={'center'}
            sx={{
                borderRadius: '10px',
                mt: 6,
                height: '80%',
                py: 3
            }}
        >
            <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M91.2474 30.21C86.3074 20.235 76.7599 12.8725 65.6924 10.45C54.1024 7.88501 42.2274 10.64 33.1549 17.955C24.0349 25.2225 18.8574 36.1 18.8574 47.7375C18.8574 60.04 26.2199 72.9125 37.3349 80.37V84.3125C37.2874 85.6425 37.2399 87.685 38.8549 89.3475C40.5174 91.0575 42.9874 91.2475 44.9349 91.2475H69.3024C71.8674 91.2475 73.8149 90.535 75.1449 89.205C76.9499 87.3525 76.9024 84.9775 76.8549 83.695V80.37C91.5799 70.4425 100.842 49.495 91.2474 30.21Z" fill="#39B54A" />
                <path d="M72.485 104.5C72.2 104.5 71.8675 104.452 71.5825 104.357C62.035 101.65 52.0125 101.65 42.465 104.357C40.7075 104.832 38.855 103.835 38.38 102.078C37.8575 100.32 38.9025 98.4675 40.66 97.9925C51.395 94.9525 62.7 94.9525 73.435 97.9925C75.1925 98.515 76.2375 100.32 75.715 102.078C75.24 103.55 73.91 104.5 72.485 104.5Z" fill="#39B54A" />
                <path d="M57 65.7875C56.3825 65.7875 55.765 65.645 55.2425 65.3125C53.5325 64.315 52.9625 62.13 53.9125 60.4675L57.95 53.4375H53.96C51.585 53.4375 49.6375 52.3925 48.5925 50.635C47.5475 48.83 47.6425 46.645 48.83 44.555L53.9125 35.72C54.91 34.01 57.095 33.44 58.7575 34.39C60.4675 35.3875 61.0375 37.5725 60.0875 39.235L56.05 46.3125H60.04C62.415 46.3125 64.3625 47.3575 65.4075 49.115C66.4525 50.92 66.3575 53.105 65.17 55.195L60.0875 64.03C59.4225 65.17 58.235 65.7875 57 65.7875Z" fill="#39B54A" />
            </svg>

            <Typography variant='h3'>
                Create Your First Knowledge Base
            </Typography>
            <Typography sx={{ maxWidth: '580px', textAlign: 'center' }}>
                Create a knowledge base to give your agents important context and
                information about your company. Once your agent is connected to a
                knowledge base, it will have the ability to handle literally thousands of FAQs,
                overcome any objection and more... with perfect recall and infinite memory.
            </Typography>
            <Button
                variant='contained'
                startIcon={<StorageIcon />}
                onClick={e => setOpenNewKnowledgeBaseModal(true)}
            >
                New Knowledge Base
            </Button>
        </Box>
    )
}