// layouts/MainLayout.js
import { Outlet } from 'react-router-dom';
import MainFooter from './MainFooter';
import { Stack } from '@mui/material';
import MainHeader from './MainHeader';

const MainLayout = () => {

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <MainHeader />
      <Stack sx={{ flex: 1, mt: 20 }}>
        <Outlet />
      </Stack>
      <MainFooter />
    </Stack>
  );
};

export default MainLayout;
