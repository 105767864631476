import * as React from "react";
import Page from "src/components/Page";
import axios from "src/utils/axios";
import useSettings from "src/hooks/useSettings";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

export default function ContactUsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [contactForm, setContactForm] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    content: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    axios
      .post("/api/v1/contacts/support/", contactForm)
      .then(() => {
        enqueueSnackbar("Your message successfully sent!");
        // Reset form fields after successful submission
        setContactForm({
          first_name: "",
          last_name: "",
          email: "",
          content: "",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Your message cannot be sent! Try again.", {
          variant: "error",
        });
      });
  };

  return (
    <Page title="Contact Us" height={"100%"} sx={{ mt: 5 }}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "10px",
            p: 3,
          }}
        >
          {
            <Stack
              sx={{
                display: "flex",
                mx: "auto",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mb: "30px",
                }}
              >
                Contact Us
              </Typography>
              <FormControl
                sx={{
                  maxWidth: {
                    sm: "60%",
                  },
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          name="first_name"
                          label="First Name"
                          type="text"
                          fullWidth
                          required
                          value={contactForm.first_name}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              first_name: e.target.value,
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="last_name"
                          label="Last Name"
                          type="text"
                          fullWidth
                          required
                          value={contactForm.last_name}
                          onChange={(e) =>
                            setContactForm({
                              ...contactForm,
                              last_name: e.target.value,
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        value={contactForm.email}
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            email: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="content"
                        label="Content"
                        type="text"
                        multiline
                        rows={5}
                        fullWidth
                        required
                        value={contactForm.content}
                        onChange={(e) =>
                          setContactForm({
                            ...contactForm,
                            content: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormControl>
            </Stack>
          }
        </Paper>
      </Container>
    </Page>
  );
}
