import * as React from 'react';
import { Box, Modal } from '@mui/material';
import AudioPlayer from 'src/components/cards/AudioPlayerCard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    maxHeight: '800px',
};

export default function AudioPlayerModal({ modalShow, setModalShow, record, audioController }) {
    const handleClose = () => {
        setModalShow(false);
        if (audioController) {
            audioController.stop();
        }
    }

    return (
        <Modal
            open={modalShow}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <AudioPlayer
                    record={record}
                    audioController={audioController}
                />
            </Box>
        </Modal>
    )
}