import { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Stack, Typography, Select, MenuItem, useMediaQuery, IconButton, Drawer } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function LandingPageHeader() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack
            sx={{
                px: 5,
                py: 3,
                justifyContent: 'space-between',
                flexDirection: 'row',
                boxShadow: '0px 4px 2px -2px rgba(0,0,0,0.1)',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: '#ffffff',
                zIndex: 1000
            }}
        >
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <img
                    src="/logo/home.png"
                    style={{ maxWidth: '140px', objectFit: 'contain' }}
                    alt="Home Page Logo"
                />
                <Stack sx={{ justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: '10px' }}>Chat Smarter</Typography>
                    <Typography sx={{ fontSize: '10px' }}>Connect Faster</Typography>
                </Stack>
            </Stack>

            {
                isMobile
                    ? <MobileMenu />
                    :
                    <>
                        <Stack direction="row" spacing={2} >
                            <Button sx={{
                                color: '#000',
                                "&:hover": {
                                    backgroundColor: '#fff',
                                    color: '#000'
                                },
                                fontSize: '16px'
                            }} onClick={() => navigate('/features')}>Features</Button>
                            <Button sx={{
                                color: '#000',
                                "&:hover": {
                                    backgroundColor: '#fff',
                                    color: '#000'
                                },
                                fontSize: '16px'
                            }} onClick={() => navigate('/how-to')}>How to</Button>
                            <Button sx={{
                                color: '#000',
                                "&:hover": {
                                    backgroundColor: '#fff',
                                    color: '#000'
                                },
                                fontSize: '16px'
                            }} onClick={() => navigate('/pricing')}>Pricing</Button>
                            <Button sx={{
                                color: '#000',
                                "&:hover": {
                                    backgroundColor: '#fff',
                                    color: '#000'
                                },
                                fontSize: '16px'
                            }} onClick={() => navigate('/about-us')}>About Us</Button>
                            <Button sx={{
                                color: '#000',
                                "&:hover": {
                                    backgroundColor: '#fff',
                                    color: '#000'
                                },
                                fontSize: '16px'
                            }} onClick={() => navigate('/contact-us')}>Contact</Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Select defaultValue="EN">
                                <MenuItem value="EN">EN</MenuItem>
                                <MenuItem value="ES">ES</MenuItem>
                                <MenuItem value="PT">PT</MenuItem>
                            </Select>
                            <SignUpButton />
                            <LoginButton />
                        </Stack>
                    </>
            }


        </Stack>
    );
}

export const MobileMenu = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <div>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleDrawerToggle}
            >
                <IconButton sx={{ mr: 0, ml: 'auto' }} onClick={e => setIsDrawerOpen(false)}>
                    <CloseIcon />
                </IconButton>
                <Stack direction="column" spacing={2} sx={{ margin: 2, mt: 10 }}>
                    <SignUpButton />
                    <LoginButton />
                </Stack>
            </Drawer>
        </div>
    );
}

export const SignUpButton = () => {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/auth/register');
    }

    return (
        <Button
            variant="contained"
            onClick={handleSignUp}
            sx={{ width: '200px' }}
        >
            Get Started for Free
        </Button>
    );
}

export const LoginButton = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/auth/login');
    }

    return (
        <Button variant="outlined" onClick={handleLogin}>
            Sign In
        </Button>
    );
}
