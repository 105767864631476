import * as React from "react";
import {
  Typography,
  Box,
  Stack,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import axios from "src/utils/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3%",
  maxHeight: "800px",
  p: 4,
};

export default function CreateContactModal({
  showModal,
  setShowModal,
  contactLists,
  contactList,
  setContactList,
  contactInfo,
  setContactInfo,
  contacts,
  setContacts,
  isUpdate = false,
}) {
  const handleClose = () => {
    setShowModal(false);
    setStep(0);
    setContactInfo({});
  };
  const [step, setStep] = React.useState(0);
  const [isAgree, setIsAgree] = React.useState(false);

  const handleSelectList = () => {
    setStep(1);
    setContactInfo({
      ...contactInfo,
      baseId: contactList.id,
    });
  };

  const handleCreateContact = () => {
    setStep(0);
    setShowModal(false);
    setContactList(null);
    setContactInfo({ userId: contactInfo.userId });
    if (isUpdate) {
      axios
        .post("/api/v1/contacts/update/", {
          id: contactInfo.id,
          baseId: contactInfo.baseId,
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          phoneNumber: contactInfo.phoneNumber,
          email: contactInfo.email,
          userId: contactInfo.userId,
          updatedAt: new Date(),
        })
        .then(() => {
          setContacts(
            contacts.map((contact) => {
              if (contact.id !== contactInfo.id) {
                return contact;
              } else {
                return contactInfo;
              }
            })
          );
        })
        .catch((err) => {
          console.log("Update Contact Error is", err);
        });
    } else {
      axios
        .post("/api/v1/contacts/create/", {
          baseId: contactInfo.baseId,
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          phoneNumber: contactInfo.phoneNumber,
          email: contactInfo.email,
          userId: contactInfo.userId,
        })
        .then((res) => {
          setContacts([...contacts, res.data]);
        });
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!isUpdate && step === 0 && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ mb: 2, display: "flex", justifyContent: "center" }}
            >
              Select a list to import leads into
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Stack sx={{ mb: 2, alignItems: "center", gap: "20px" }}>
                <Select
                  placeholder="Search ..."
                  sx={{ width: "100%", mt: 4 }}
                  onChange={(e) => setContactList(e.target.value)}
                  value={contactList}
                >
                  {contactLists.length &&
                    contactLists.map((list) => (
                      <MenuItem key={list.id} value={list}>
                        {list.name}
                      </MenuItem>
                    ))}
                </Select>
                <Typography>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={isAgree}
                        onChange={(e) => setIsAgree(e.target.checked)}
                      />
                    }
                    label="By checking this box, I confirm that all the contacts I am importing have given me express written consent to contact them with artificial and pre-recorded voice calls. I also agree to comply with all relevant TCPA, TSR and regulatory laws/guidelines concerning my communication with these contacts."
                  />
                </Typography>
              </Stack>
              <Stack sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  display={"flex"}
                  sx={{ ml: "auto", mr: 0 }}
                  onClick={handleSelectList}
                >
                  Select
                </Button>
              </Stack>
            </Typography>
          </>
        )}
        {(isUpdate || step === 1) && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{ mb: 2, display: "flex", justifyContent: "center" }}
            >
              {isUpdate ? "Update Contact" : "Create New Contact"}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Stack
                sx={{
                  mb: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "25%" }}>
                  <Typography htmlFor="first-name" component="label">
                    First Name:
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "70%" }}
                  id="first-name"
                  label="First Name*"
                  value={contactInfo.firstName}
                  onChange={(e) =>
                    setContactInfo({
                      ...contactInfo,
                      firstName: e.target.value,
                    })
                  }
                />
              </Stack>
              <Stack
                sx={{
                  mb: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "25%" }}>
                  <Typography htmlFor="last-name" component="label">
                    Last Name:
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "70%" }}
                  id="last-name"
                  label="Last Name*"
                  value={contactInfo.lastName}
                  onChange={(e) =>
                    setContactInfo({
                      ...contactInfo,
                      lastName: e.target.value,
                    })
                  }
                />
              </Stack>
              <Stack
                sx={{
                  mb: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "25%" }}>
                  <Typography htmlFor="email" component="label">
                    Email:
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "70%" }}
                  id="email"
                  label="Email*"
                  value={contactInfo.email}
                  onChange={(e) =>
                    setContactInfo({
                      ...contactInfo,
                      email: e.target.value,
                    })
                  }
                />
              </Stack>
              <Stack
                sx={{
                  mb: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Box sx={{ width: "25%" }}>
                  <Typography htmlFor="phone-number" component="label">
                    Phone Number:
                  </Typography>
                </Box>
                <TextField
                  sx={{ width: "70%" }}
                  id="phone-number"
                  label="Phone Number*"
                  value={contactInfo.phoneNumber}
                  onChange={(e) =>
                    setContactInfo({
                      ...contactInfo,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </Stack>
              <Stack sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  display={"flex"}
                  sx={{ ml: "auto", mr: 0 }}
                  onClick={handleCreateContact}
                >
                  {isUpdate ? "Update" : "Create"}
                </Button>
              </Stack>
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}
