import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const FIREBASE_API = {
  apiKey: "AIzaSyCWTKWH3naGEcuyah8u0Bjx6MbwikPUduE",
  authDomain: "omodore-26900.firebaseapp.com",
  projectId: "omodore-26900",
  storageBucket: "omodore-26900.appspot.com",
  messagingSenderId: "904111756385",
  appId: "1:904111756385:web:40d0ee939071fcd7504620",
  measurementId: "G-C0KLLNESJ7"
}

// Initialize Firebase
const app = initializeApp(FIREBASE_API);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account "
});

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export default app;