import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import CreateKnowledgeBase from 'src/pages/dashboard/knowledgeBases/CreateKnowledgeBase';
import EditKnowledgeBase from 'src/pages/dashboard/knowledgeBases/EditKnowledgeBase';
import Agents from 'src/pages/dashboard/agents';
import KnowledgeBases from 'src/pages/dashboard/knowledgeBases';
import RecordingsPage from 'src/pages/dashboard/recordings';
import CreateContactPage from 'src/pages/dashboard/contact/CreateContact';
import ContactListPage from 'src/pages/dashboard/contact/ContactLists';
import PrivacyPolicyPage from 'src/pages/dashboard/privacyPolicy';
import TermsAndConditionsPage from 'src/pages/dashboard/termsAndCondition';
import ContactUsPage from 'src/pages/dashboard/contactUs';
import HomePage from 'src/pages/home';
import HelpPage from 'src/pages/dashboard/help';
import BlogPage from 'src/pages/dashboard/blog';

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <HomePage />
        }
      ]
    },
    {
      path: 'auth',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'user',
          children: [
            { element: <Navigate to='/dashboard/user/profile' replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },
    {
      path: 'agents',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Agents />, index: true },
        { path: 'create', element: <CreateScript /> },
        { path: 'edit/:id', element: <EditScript /> },
      ],
    },
    {
      path: 'knowledge-bases',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <KnowledgeBases />, index: true },
        { path: 'create', element: <CreateKnowledgeBase /> },
        { path: 'edit/:id', element: <EditKnowledgeBase /> },
      ],
    },
    {
      path: 'contact',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ContactPage />, index: true },
        { path: 'create', element: <CreateContactPage /> },
        { path: 'lists', element: <ContactListPage /> },
      ],
    },
    {
      path: 'recordings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <RecordingsPage />, index: true },
        {
          path: 'user',
          children: [
            { element: <Navigate to='/dashboard/user/profile' replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },
    {
      path: 'contact-us',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ContactUsPage />, index: true },
      ],
    },

    {
      element: <MainLayout />,
      children: [
        {
          path: 'policy',
          children: [
            { element: <PrivacyPolicyPage />, index: true },
          ],
        },
        {
          path: 'terms',
          children: [
            { element: <TermsAndConditionsPage />, index: true },
          ],
        },
        {
          path: 'help',
          children: [
            { element: <HelpPage />, index: true },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <BlogPage />, index: true },
          ],
        },
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Page404 /> },
      ],
    },
    { path: '/', element: <HomePage /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// GENERAL
const EditScript = Loadable(lazy(() => import('../pages/dashboard/agents/EditScript')));
const CreateScript = Loadable(lazy(() => import('../pages/dashboard/agents/CreateScript')));

const ContactPage = Loadable(lazy(() => import('../pages/dashboard/contact')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
