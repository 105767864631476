import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'src/redux/slices/user';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

export default function ContactEmpty({ setShowModal, showModal }) {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={3}
            alignItems={'center'}
            sx={{
                borderRadius: '10px',
                mt: 6,
                height: '80%',
                py: 3
            }}
        >
            <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M83.2674 36.9075C82.9349 36.86 82.6024 36.86 82.2699 36.9075C74.9074 36.67 69.0649 30.6375 69.0649 23.2275C69.0649 15.675 75.1924 9.5 82.7924 9.5C90.3449 9.5 96.5199 15.6275 96.5199 23.2275C96.4724 30.6375 90.6299 36.67 83.2674 36.9075Z" fill="#39B54A" />
                <path opacity="0.4" d="M98.7526 69.825C93.4326 73.3875 85.9751 74.7175 79.0876 73.815C80.8926 69.92 81.8426 65.5976 81.8901 61.0376C81.8901 56.2876 80.8451 51.7751 78.8501 47.8326C85.8801 46.8826 93.3376 48.2125 98.7051 51.775C106.21 56.715 106.21 64.8375 98.7526 69.825Z" fill="#39B54A" />
                <path opacity="0.4" d="M30.5899 36.9075C30.9224 36.86 31.2549 36.86 31.5874 36.9075C38.9499 36.67 44.7924 30.6375 44.7924 23.2275C44.7924 15.675 38.6649 9.5 31.0649 9.5C23.5124 9.5 17.3374 15.6275 17.3374 23.2275C17.3849 30.6375 23.2274 36.67 30.5899 36.9075Z" fill="#39B54A" />
                <path opacity="0.4" d="M31.1123 61.0375C31.1123 65.645 32.1098 70.015 33.9148 73.9575C27.2173 74.67 20.2348 73.245 15.1048 69.8725C7.59982 64.885 7.59982 56.7625 15.1048 51.775C20.1873 48.355 27.3598 46.9775 34.1048 47.7375C32.1573 51.7275 31.1123 56.24 31.1123 61.0375Z" fill="#39B54A" />
                <path d="M57.5698 75.3825C57.1898 75.335 56.7623 75.335 56.3348 75.3825C47.5948 75.0975 40.6123 67.925 40.6123 59.09C40.6123 50.065 47.8798 42.75 56.9523 42.75C65.9773 42.75 73.2923 50.065 73.2923 59.09C73.2923 67.925 66.3573 75.0975 57.5698 75.3825Z" fill="#39B54A" />
                <path d="M42.1323 85.215C34.9598 90.0125 34.9598 97.8975 42.1323 102.648C50.3023 108.11 63.6973 108.11 71.8673 102.648C79.0398 97.85 79.0398 89.965 71.8673 85.215C63.7448 79.7525 50.3498 79.7525 42.1323 85.215Z" fill="#39B54A" />
            </svg>

            <Typography variant='h3'>
                Don’t have any contact list yet!
            </Typography>
            <Typography sx={{ maxWidth: '580px', textAlign: 'center' }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            </Typography>
            <Button
                variant='contained'
                startIcon={<ConnectWithoutContactIcon />}
                onClick={e => setShowModal(true)}
            >
                Create New Contact
            </Button>
        </Box>
    )
}