import * as React from 'react';
import { Box, Button, Modal, Stack, Typography, TextField, MenuItem } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import Image from 'src/components/Image';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

export default function LanguageModal({ modalShow, setModalShow }) {

    const { allLangs, currentLang, onChangeLang } = useLocales();

    const handleClose = () => {
        setModalShow(false);
    }

    const handleChangeLang = (newLang) => {
        onChangeLang(newLang);
        handleClose();
    };

    return (
        <Modal
            open={modalShow}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
                    Languages Setup
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 4 }} justifyContent={'center'} textAlign={'center'}>
                    <Stack flexDirection={'row'} flexWrap={'wrap'} gap={1}>
                        {allLangs.map((option) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === currentLang.value}
                                sx={{ width: '30%' }}
                                onClick={() => handleChangeLang(option.value)}
                            >
                                <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

                                {option.label}
                            </MenuItem>
                        ))}
                    </Stack>
                </Typography>
            </Box>
        </Modal>
    )
}