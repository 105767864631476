import * as React from 'react';
import { Typography, Box, Stack, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '3%',
    maxHeight: '800px',
    p: 4,
};


export default function CreateNewKknowledgeBaseModal({ openNewKnowledgeBaseModal, setOpenNewKnowledgeBaseModal, knowledgeBase, setKnowledgeBase, knowledgeBases, setKnowledgeBases }) {
    const handleClose = () => setOpenNewKnowledgeBaseModal(false);
    const navigate = useNavigate();

    const handleCreateKnowledgeBase = () => {
        handleClose();
        axios
            .post('/api/v1/knowledgebases/create/', {
                userId: knowledgeBase.userId,
                name: knowledgeBase.name
            })
            .then(result => {
                setKnowledgeBases([...knowledgeBases, result.data]);
            })
            .catch(err => {
                console.log('error is ', err);
            })
        navigate('/knowledge-bases/');
    }

    return (
        <Modal
            open={openNewKnowledgeBaseModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    Create a New Knowledge Base
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Typography>
                        Create a new knowledge base for Air to answer questions for you
                    </Typography>
                    <Stack sx={{ mb: 2, flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                        <label htmlFor='name'>Name </label>
                        <TextField
                            id="name"
                            label="eg.Support Knowledge Base*"
                            sx={{ mt: 1, width: '100%' }}
                            onChange={e => setKnowledgeBase({ ...knowledgeBase, name: e.target.value })}
                        />
                    </Stack>
                    <Stack sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            display={'flex'}
                            sx={{ ml: 'auto', mr: 0 }}
                            onClick={handleCreateKnowledgeBase}
                        >
                            Create
                        </Button>
                    </Stack>
                </Typography>
            </Box>
        </Modal >
    )
}