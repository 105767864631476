// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
export const ROOTS_AGENT = "/agents";
export const ROOTS_KNOWLEDGEBASE = "/knowledge-bases";
export const ROOTS_CONTACT = "/contact";
export const ROOTS_RECORDINGS = "/recordings";
export const ROOTS_POLICY = "/policy";
export const ROOTS_TERMSANDCONDITIONS = "/terms";
export const ROOTS_CONTACTUS = "/contact-us";
export const ROOTS_HELP = "/help";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
  contact_us: "/contact-us",
  help: "/help",
  policy: "/policy",
  terms: "/terms",
  blog: "/blog",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    agents: path(ROOTS_AGENT, "/"),
    knowledgeBases: path(ROOTS_KNOWLEDGEBASE, "/"),
  },

  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),

    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  contact: {
    root: path(ROOTS_CONTACT, "/"),
    create: path(ROOTS_CONTACT, "/create"),
    lists: path(ROOTS_CONTACT, "/lists"),
  },
  recordings: {
    root: path(ROOTS_RECORDINGS, "/"),
  },
  policy: {
    root: path(ROOTS_POLICY, "/"),
  },
  terms: {
    root: path(ROOTS_TERMSANDCONDITIONS, "/"),
  },
  contactUs: {
    root: path(ROOTS_CONTACTUS, "/"),
  },
  help: {
    root: path(ROOTS_HELP, "/"),
  },
};

export const PATH_DOCS = "";
export const API_DOCS = "";
