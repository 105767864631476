import * as React from "react";
import jwtDecode from "jwt-decode";
import Page from "src/components/Page";
import CreateContactModal from "src/components/modals/contact/CreateContactModal";
import ContactEmpty from "src/sections/contact/emptyContact";
import axios from "src/utils/axios";
import useSettings from "src/hooks/useSettings";
import {
  Container,
  Stack,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContactImportModal from "src/components/modals/contact/ContactImportModal";

export default function CreateContactPage() {
  const userId = jwtDecode(localStorage.accessToken).user_id;
  const { themeStretch } = useSettings();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [contactLists, setContactLists] = React.useState([]);
  const [contactList, setContactList] = React.useState('');
  const [contacts, setContacts] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [contactInfo, setContactInfo] = React.useState({
    userId: userId,
    firstName: "",
    lastName: "",
    baseId: "",
    phoneNumber: "",
    email: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    { id: "first_name", label: "First Name", minWidth: 120 },
    { id: "last_name", label: "Last Name", minWidth: 120 },
    { id: "phone_number", label: "Phone Number", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "updated_at", label: "Last Modified", minWidth: 150 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const section = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
    "updated_at",
  ];

  React.useEffect(() => {
    axios
      .get("/api/v1/contacts/get/" + userId)
      .then((res) => {
        setContacts([...res.data]);
      })
      .catch((err) => {
        console.log("Get contact list error is", err);
      });

    axios
      .get("/api/v1/contacts/lists/" + userId)
      .then((res) => {
        setContactLists([...res.data]);
      })
      .catch((err) => {
        console.log("Get contact list error is", err);
      });
  }, []);

  const handleCreateContact = () => {
    setShowCreateModal(true);
    setIsUpdate(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSingleContact = (data) => {
    setShowCreateModal(true);
    setIsUpdate(true);
    setContactInfo(data);
  };

  const handleDeleteContactList = (e, cur) => {
    e.stopPropagation();
    const prevLists = contacts;
    axios.delete(`/api/v1/contacts/sub/delete/${cur.id}`).then(() => {
      setContacts(prevLists.filter((item) => item.id !== cur.id));
    });
  };

  return (
    <Page title="Contact" height={"100%"}>
      {contacts.length ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Stack direction="row">
            <Button
              variant="contained"
              display={"flex"}
              sx={{ ml: "auto", mr: 0, my: 3 }}
              onClick={handleCreateContact}
            >
              New Contact
            </Button>
          </Stack>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid rgba(65,65,65, 0.13)",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          key={row.companyName + index}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => handleSingleContact(row)}
                        >
                          {section.map((column, index1) => {
                            const value = row[column];
                            return (
                              <TableCell
                                key={column + index1}
                                align={column.align}
                              >
                                {column !== "updated_at"
                                  ? value
                                  : new Date(value)
                                      .toLocaleString("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })
                                      .toString()}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => handleDeleteContactList(e, row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={contacts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Container>
      ) : (
        <ContactEmpty
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
        />
      )}
      <CreateContactModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        contactLists={contactLists}
        contactList={contactList}
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        setContactList={setContactList}
        contacts={contacts}
        setContacts={setContacts}
        isUpdate={isUpdate}
      />

      <ContactImportModal
        showModal={showImportModal}
        setShowModal={setShowImportModal}
      />
    </Page>
  );
}
