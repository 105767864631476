import * as React from 'react';
import jwtDecode from 'jwt-decode';
import Page from 'src/components/Page';
import axios from 'src/utils/axios';
import useSettings from 'src/hooks/useSettings';
import { Container, Stack, Button, Box, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1024,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    maxHeight: '800px',
};


export default function ContactListModal({ showModal, setShowModal, contacts, contactList }) {
    const { themeStretch } = useSettings();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = [
        { id: 'first_name', label: 'First Name', minWidth: 120 },
        { id: 'last_name', label: 'Last Name', minWidth: 120 },
        { id: 'phone_number', label: 'Phone Number', minWidth: 170 },
        { id: 'email', label: 'Email', minWidth: 100 },
        { id: 'updated_at', label: 'Last Modified', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    const section = [
        'firstName',
        'lastName',
        'phoneNumber',
        'email',
        'updated_at'
    ];

    const handleClose = () => setShowModal(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                    {contactList?.name}
                </Typography>

                <Page title="Contact" height={'100%'} >
                    {
                        contacts.length &&
                        <Container maxWidth={themeStretch ? false : 'lg'}>
                            <Paper sx={{ width: '100%', overflow: 'hidden', border: '1px solid rgba(65,65,65, 0.13)', borderRadius: '10px', p: 3 }}>
                                <TableContainer sx={{ maxHeight: 800 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, index) => {
                                                        return (
                                                            <TableRow key={row.companyName + index}>
                                                                {section.map((column, index1) => {
                                                                    const value = row[column];
                                                                    return (
                                                                        <TableCell key={column + index1} align={column.align}>
                                                                            {column !== 'updated_at' ?
                                                                                value :
                                                                                new Date(value).toLocaleString('en-GB', {
                                                                                    day: '2-digit',
                                                                                    month: 'short',
                                                                                    year: 'numeric',
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                })
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                                <TableCell>
                                                                    < IconButton
                                                                        aria-label="delete"
                                                                        onClick={e => handleDeleteContactList(e, row)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton >
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={contacts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Container>
                    }
                </Page>
            </Box>
        </Modal>
    )
}