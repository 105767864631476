import jwtDecode from "jwt-decode";
import * as React from "react";
import Page from "src/components/Page";
import CreateContactListModal from "src/components/modals/contact/CreateContactListModal";
import axios from "src/utils/axios";
import useSettings from "src/hooks/useSettings";
import {
  Container,
  Stack,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContactListEmpty from "src/sections/contact/emptyContactList";
import ContactListModal from "src/components/modals/contact/ContactListModal";

export default function ContactListPage() {
  const { themeStretch } = useSettings();
  const [showModal, setShowModal] = React.useState(false);
  const [showContactModal, setShowContactModal] = React.useState(false);
  const [contactLists, setContactLists] = React.useState([]);
  const [contactList, setContactList] = React.useState(null);
  const [contacts, setContacts] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userId = jwtDecode(localStorage.accessToken).user_id;

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "last_edited", label: "Last Edited", minWidth: 150 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const section = ["name", "updated_at"];

  React.useEffect(() => {
    axios
      .get("/api/v1/contacts/lists/" + userId)
      .then((res) => {
        setContactLists(res.data);
      })
      .catch((err) => {
        console.log("Get contact list error is", err);
      });
  }, []);

  const handleCreateContact = () => {
    setShowModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDeleteContactList = (e, cur) => {
    e.stopPropagation();
    const prevLists = contactLists;
    axios.delete(`/api/v1/contacts/lists/delete/${cur.id}`).then((res) => {
      setContactLists(() => {
        return prevLists.filter((item) => item.id !== cur.id);
      });
    });
  };

  const handleSingleContact = (contact) => {
    setContactList(contact);

    axios
      .get("/api/v1/contacts/get/single/" + contact.id)
      .then((res) => {
        setContacts([...res.data]);
        res.data.length && setShowContactModal(true);
      })
      .catch((err) => {
        console.log("Get contact list error is", err);
      });
  };

  return (
    <Page title="Contact" height={"100%"}>
      {contactLists.length ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Stack direction="row">
            <Button
              variant="contained"
              display={"flex"}
              sx={{ ml: "auto", mr: 0, my: 3 }}
              onClick={handleCreateContact}
            >
              New Contact List
            </Button>
          </Stack>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid rgba(65,65,65, 0.13)",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactLists
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          key={row.companyName + index}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => handleSingleContact(row)}
                        >
                          {section.map((column, index1) => {
                            const value = row[column];
                            return (
                              <TableCell
                                key={column + index1}
                                align={column.align}
                              >
                                {column !== "updated_at"
                                  ? value
                                  : new Date(value).toLocaleString("en-GB", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => handleDeleteContactList(e, row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={contactLists.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Container>
      ) : (
        <ContactListEmpty showModal={showModal} setShowModal={setShowModal} />
      )}
      <CreateContactListModal
        showModal={showModal}
        setShowModal={setShowModal}
        userId={userId}
        contactLists={contactLists}
        setContactLists={setContactLists}
      />
      <ContactListModal
        showModal={showContactModal}
        setShowModal={setShowContactModal}
        contacts={contacts}
        setContacts={setContacts}
        contactList={contactList}
      />
    </Page>
  );
}
