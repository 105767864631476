import * as React from 'react';
import { Box, Button, Modal, Stack, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import KnowledgeBaseCard from 'src/components/cards/KnowledgeBaseCard';

export default function AddToKnowledgeBaseModal({ addToKnowledgeBaseModalShow, setAddToKnowledgeBaseModalShow, knowledgeBaseId, subBases, setSubBases }) {
    const handleClose = () => {
        setAddToKnowledgeBaseModalShow(false);
        setStep(1);
        setMethod('manual');
    }
    const [step, setStep] = React.useState(1);
    const [method, setMethod] = React.useState('manual');

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
            },
        }),
    );

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    const handleSelect = () => {
        setStep(2);
    }

    return (
        <Modal
            open={addToKnowledgeBaseModalShow}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                {
                    step === 1 && method === 'manual' &&
                    <KnowledgeBaseCard
                        knowledgeBaseId={knowledgeBaseId}
                        setModalShow={setAddToKnowledgeBaseModalShow}
                        subBases={subBases}
                        setSubBases={setSubBases}
                    />
                }
                {/* {
                    step === 1 &&
                    <>
                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                            Add to Knowledge Base
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} justifyContent={'center'} textAlign={'center'}>
                            Do you want AI to magically turn documents into FAQ flashcards or add them manually?
                        </Typography>

                        <RadioGroup
                            name="use-radio-group"
                            defaultValue="manual"
                            onChange={e => setMethod(e.target.value)}
                        >
                            <MyFormControlLabel value="manual" label="Manually and FAQ flashcard" control={<Radio />} />
                            <MyFormControlLabel value="upload" label="Upload documents" control={<Radio />} />
                        </RadioGroup>

                        <Stack justifyContent={'center'} display={'flex'} flexDirection={'row'} >
                            <Button
                                variant='contained'
                                sx={{ maxWidth: '50px' }}
                                onClick={e => handleSelect()}>
                                Select
                            </Button>
                        </Stack>
                    </>
                }
                {
                    step === 2 && method === 'manual' &&
                    <KnowledgeBaseCard
                        knowledgeBaseId={knowledgeBaseId}
                        setModalShow={setAddToKnowledgeBaseModalShow}
                        subBases={subBases}
                        setSubBases={setSubBases}
                    />
                } */}

            </div>
        </Modal>
    )
}