import { IconButton, Stack, Typography } from "@mui/material";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Image from "src/components/Image";
import { useState } from "react";

export default function BlogCard({ blog }) {
    const [isActive, setIsActive] = useState(false);

    return (
        <Stack sx={{ padding: { xs: 1, sm: 3 } }}>
            <img src={blog?.imageUrl} />
            <Stack
                spacing={3}
                sx={{
                    padding: blog?.section === 'main' ? { xs: 3, sm: 5 } : 0,
                    mt: blog?.section === 'main' ? '-150px' : '20px',
                    zIndex: '1001',
                    background: '#fff',
                    width: blog?.section === 'main' ? '90%' : '100%',
                    mx: 'auto',
                    borderRadius: '4px'
                }}>
                <Stack spacing={2} direction={!isActive ? 'column' : 'row'}>
                    {isActive &&
                        <Stack sx={{ ml: 'auto', mr: 0, mt: '-10px !important' }} >
                            <IconButton sx={{
                                width: "48px",
                                height: "48px",
                            }}>
                                <BookmarkBorderIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    width: "48px",
                                    height: "48px",
                                }}
                                onClick={e => setIsActive(!isActive)}
                            >
                                <Image src="/assets/icons/send_dark.png" alt="send icon" />
                            </IconButton>
                        </Stack>
                    }
                    <Stack spacing={3} sx={{ width: '100%' }}>
                        <Stack direction='row' sx={{ fontSize: '14px', alignItems: 'center' }}>
                            <Typography>
                                <span style={{ color: '#0053A7', marginRight: '10px' }}>{blog?.topic}</span>
                                {blog?.createdAt}
                            </Typography>
                            {
                                isActive &&
                                <Typography
                                    sx={{ ml: 'auto', mr: 0, cursor: 'pointer', color: '#3EB54B', fontSize: '12px' }}
                                    onClick={e => setIsActive(!isActive)}>
                                    {'< '}Back to blog
                                </Typography>
                            }
                        </Stack>

                        <Typography
                            sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24.2px', cursor: 'pointer' }}
                            onClick={e => setIsActive(!isActive)}>
                            {blog?.title}
                        </Typography>
                    </Stack>
                </Stack>

                {
                    !isActive ?
                        <Typography sx={{ fontSize: '12px' }}>
                            {blog.content}
                        </Typography> :
                        <BlogTemplate blog={blog} />
                }

                {
                    !isActive ?

                        <Stack direction='row'>
                            <Stack>
                                <Typography sx={{ fontWeight: '600' }}>{blog?.visitors}</Typography> People saw
                            </Stack>
                            <Stack direction='row' sx={{ ml: 'auto', mr: 0 }}>
                                <IconButton
                                    sx={{
                                        width: "48px",
                                        height: "48px",
                                    }}

                                >
                                    <Image src="/assets/icons/send_dark.png" alt="send icon" />
                                </IconButton>
                                <IconButton sx={{
                                    width: "48px",
                                    height: "48px",
                                }}>
                                    <BookmarkBorderIcon />
                                </IconButton>
                            </Stack>
                        </Stack> : ''
                }

            </Stack>
        </Stack>
    )
}

export const BlogTemplate = ({ blog }) => {
    return (
        <Stack spacing={2} sx={{ fontSize: '12px' }}>
            <Typography sx={{ fontSize: '12px' }}>
                {blog?.header}
            </Typography>
            {
                blog?.details.map((detail, index) => (
                    <Stack key={`detail-${index}`} spacing={2}>
                        <Stack>
                            <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>
                                {detail?.topic}
                            </Typography>
                            {detail?.desc}
                        </Stack>
                        <Typography sx={{ fontSize: '12px' }}>Key features:</Typography>
                        <Stack>
                            {
                                detail?.features.map((feature, index1) => (
                                    <Typography key={`feature-${index1}`} sx={{ fontSize: '12px' }}>
                                        {feature}
                                    </Typography>
                                ))
                            }
                        </Stack>
                    </Stack>
                ))
            }
            <Typography sx={{ fontSize: '12px' }}>
                {blog?.footer}
            </Typography>
        </Stack>
    )
}