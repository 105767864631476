import * as React from 'react';
import { Typography, Stack, TextField, Button, Box } from '@mui/material';
import axios from 'src/utils/axios';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "80%",
    md: "600px",
  },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "20px",
};

export default function KnowledgeBaseCard({ title = "Create A Flash Card", knowledgeBaseId, data = null, setModalShow, subBases, setSubBases }) {
    const [question, setQuestion] = React.useState('');
    const [answer, setAnswer] = React.useState('');

    React.useEffect(() => {
        if (data) {
            setQuestion(data.question);
            setAnswer(data.answer);
        }
    }, [data])

    const handleManually = () => {
        if (!data) {
            axios
                .post('/api/v1/knowledgebases/sub/create/', {
                    baseId: knowledgeBaseId,
                    question: question,
                    answer: answer
                })
                .then(res => {
                    setSubBases([...subBases, res.data]);
                })
                .catch(err => {
                    console.log('error is', err);
                });
        } else {
            axios
                .post('/api/v1/knowledgebases/sub/update/', {
                    id: data.id,
                    question: question,
                    answer: answer,
                    data_id: data.data_id,
                    baseId: data.baseId
                })
                .then(res => {
                    const prevBases = subBases;
                    setSubBases(() => {
                        return prevBases.map(item => {
                            if (item.id === data.id) {
                                return res.data;
                            }
                            return item;
                        });
                    });
                })
                .catch(err => {
                    console.log('error is', err);
                });
        }

        setModalShow(false);

    }

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                {title}
            </Typography>

            <Stack sx={{ mb: 2 }}>
                <label htmlFor='question'>Question/Objection </label>
                <TextField
                    id="question"
                    placeholder="eg. What's the name of your company?"
                    multiline
                    maxRows={4}
                    sx={{ mt: 1 }}
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                />
            </Stack>

            <Stack sx={{ mb: 2 }}>
                <label htmlFor='answer'>Answer/Response </label>
                <TextField
                    id="answer"
                    placeholder="eg. Tesla Super Computers"
                    multiline
                    rows={4}
                    sx={{ mt: 1 }}
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                />
            </Stack>

            <Stack justifyContent={'flex-end'} display={'flex'} flexDirection={'row'} >
                <Button
                    variant='contained'
                    sx={{ maxWidth: '80px' }}
                    onClick={e => handleManually()}>
                    {
                        !data ? 'Create' : 'Update'
                    }
                </Button>
            </Stack>
        </Box>
    )
}