import * as React from 'react';
import { Modal } from '@mui/material';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import KnowledgeBaseCard from './KnowledgeBaseCard';
import axios from 'src/utils/axios';

export default function SubKnowledgeBaseCard({ data, subBases, setSubBases }) {
    const [modalShow, setModalShow] = React.useState(false);
    const handleClose = () => {
        setModalShow(false);
    }

    const handleDeleteSubBase = (e) => {
        e.stopPropagation();
        const prevBases = subBases;
        axios
            .delete(`/api/v1/knowledgebases/sub/delete/${data.id}`)
            .then(res => {
                setSubBases(() => {
                    return prevBases.filter(item => item.id !== data.id);
                });
            })
    }

    return (
        <>
            <Paper
                elevation={6}
                sx={{
                    height: '200px',
                    border: '1px solid rgba(65,65,65,.3)',
                    borderRadius: '10px',
                    pl: 2,
                    cursor: 'pointer',
                }}
                onClick={e => setModalShow(true)}
            >
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                    <IconButton onClick={e => handleDeleteSubBase(e)}>
                        <HighlightOffSharpIcon />
                    </IconButton>
                </Box>
                <Box sx={{ pr: 2 }}>
                    <Typography fontWeight={'700'} sx={{ mb: 2 }}>
                        {data.question}
                    </Typography>
                    <Typography fontSize={'14px'}>
                        {data.answer}
                    </Typography>
                </Box>
            </Paper>
            <Modal
                open={modalShow}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <KnowledgeBaseCard
                    title="Update Flash Card"
                    data={data}
                    setModalShow={setModalShow}
                    subBases={subBases}
                    setSubBases={setSubBases}
                />
            </Modal>
        </>
    )
}