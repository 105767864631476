import * as React from 'react';
import { Typography, Box, Stack, Button, Select, MenuItem } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '3%',
    maxHeight: '800px',
    p: 4,
};

export default function CreateContactListModal({ showModal, setShowModal, userId, contactLists, setContactLists }) {
    const handleClose = () => setShowModal(false);
    const navigate = useNavigate();
    const [listName, setListName] = React.useState('');

    const handleCreateList = () => {
        axios
            .post('/api/v1/contacts/lists/create/', {
                name: listName,
                userId: userId
            })
            .then(res => {
                setContactLists([...contactLists, res.data]);
            })
            .catch(err => {
                console.log('Create New Contact List Error is', err);
            });
        setShowModal(false);

    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    Create New List
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    </Typography>
                    <Stack sx={{ mb: 2, flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                        <TextField
                            id="name"
                            label="Enter your list name..."
                            sx={{ mt: 1, width: '100%' }}
                            onChange={e => setListName(e.target.value)}
                        />
                    </Stack>
                    <Stack sx={{ mb: 2, justifyContent: 'center', flexDirection: 'row' }}>
                        <Button
                            variant="contained"
                            display={'flex'}
                            onClick={handleCreateList}
                        >
                            Create List
                        </Button>
                    </Stack>
                </Typography>
            </Box>
        </Modal >
    )
}