import * as React from "react";
import { Box, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import BasicChart from "../chart/BasicChart";
import SuccessChart from "../chart/SuccessChart";

export default function ChartModal({
  modalShow,
  setModalShow,
  data,
  title,
  isLegend,
}) {
  const handleClose = () => {
    setModalShow(false);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isLegend
      ? "90%"
      : isSmallScreen
      ? "90%"
      : isMediumScreen
      ? "60%"
      : "30%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: isSmallScreen ? 2 : 4,
  };

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant={isSmallScreen ? "h5" : "h4"}
          component="h2"
          sx={{ mb: 4, display: "flex", justifyContent: "center" }}
        >
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 4 }}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {isLegend ? <SuccessChart data={data} /> : <BasicChart data={data} />}
        </Typography>
      </Box>
    </Modal>
  );
}
