import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices

import userReducer from './slices/user';
import agentReducer from './slices/agent';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const rootReducer = combineReducers({
  user: userReducer,
  agent: agentReducer,
})

export { rootPersistConfig, rootReducer }
