import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
  Fade,
} from "@mui/material";
import axios from "src/utils/axios";
import CloseIcon from "@mui/icons-material/Close";
import Image from "./Image";
import MyAvatar from "./MyAvatar";

const customScrollbar = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    border: "2px solid #f1f1f1",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
};

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = () => {
    if (!userInput) return;

    setMessages([...messages, { type: "user", text: userInput }]);

    setUserInput("");

    axios
      .post("/api/v1/chatbot/chat/", {
        message: userInput,
      })
      .then((res) => {
        const botResponse = res.data.response;
        const modifiedBotResponse = convertUrlsToLinks(botResponse);
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "bot", text: modifiedBotResponse },
        ]);
      });
  };

  const convertUrlsToLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "50px" }}>
      {!chatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000000,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#fff",
              display: showHelp ? 'inline-block' : 'none',
              borderRadius: "12px 12px 0px 12px",
              p: 2,
              textAlign: "left",
              maxWidth: "70%",
              position: "relative",
              boxShadow: 3,
              mb: 2,
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-10px',
                right: '0px',
                left: 'auto',
                width: 0,
                height: 0,
                border: '10px solid transparent',
                borderTopColor: '#fff',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: '10px solid transparent',
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                bottom: '-10px',
                right: '0px',
                left: 'auto',
                width: 0,
                height: 0,
                border: '10px solid transparent',
                borderTopColor: '#fff',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: '10px solid transparent',
                filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))',
              },
            }}
          >
            <IconButton
              onClick={e => setShowHelp(false)}
              sx={{
                position: 'absolute',
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                color: 'black',
                marginLeft: '85px',
                marginTop: '-20px'
              }}
            >
              <CloseIcon />
            </IconButton>
            Hey! Need some help?
          </Typography>
          <Fade in={!chatOpen}>
            <IconButton
              color="primary"
              onClick={toggleChat}
              sx={{
                borderRadius: "50%",
                width: "65px",
                height: "65px",
                padding: '13px',
                backgroundColor: "#3EB54B",
                "&:hover": {
                  backgroundColor: "#007B55",
                },
                color: "white",
              }}
            >
              <Image src="/assets/icons/chatbot.png" alt="AI Chat Bot" />
            </IconButton>
          </Fade>
        </Box>

      )}

      <Collapse in={chatOpen} unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 10001,
            width: "350px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease-in-out",
            transform: chatOpen ? "translateY(0)" : "translateY(100%)",
            borderRadius: "15px",
            height: "500px",
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              backgroundColor: "#3EB54B",
              color: "white",
              display: "flex",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Omodore
            </Typography>
            <IconButton
              color="inherit"
              onClick={toggleChat}
              sx={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: 2,
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "white",
              ...customScrollbar,
            }}
          >
            <List>
              {messages.map((message, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      message.type === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  <ListItemText
                    primary={
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent:
                              message.type === "user" ? "flex-end" : "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              backgroundColor:
                                message.type === "user" ? "#dcf8c6" : "#fff",
                              borderRadius:
                                message.type === "user"
                                  ? "12px 12px 0px 12px"
                                  : "12px 12px 12px 0px",
                              p: 2,
                              textAlign: "left",
                              maxWidth: "70%",
                              display: "inline-block",
                              position: "relative",
                              boxShadow: 3,
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-10px',
                                right: message.type === "user" ? '0px' : 'auto',
                                left: message.type === "user" ? 'auto' : '0px',
                                width: 0,
                                height: 0,
                                border: '10px solid transparent',
                                borderTopColor: message.type === "user" ? '#dcf8c6' : '#fff',
                                borderBottom: 0,
                                borderRight: message.type === "user" ? 0 : '10px solid transparent',
                                borderLeft: message.type === "user" ? '10px solid transparent' : 0,
                              },
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-10px',
                                right: message.type === "user" ? '0px' : 'auto',
                                left: message.type === "user" ? 'auto' : '0px',
                                width: 0,
                                height: 0,
                                border: '10px solid transparent',
                                borderTopColor: message.type === "user" ? '#dcf8c6' : '#fff',
                                borderBottom: 0,
                                borderRight: message.type === "user" ? 0 : '10px solid transparent',
                                borderLeft: message.type === "user" ? '10px solid transparent' : 0,
                                filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))',
                              },
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                          </Typography>
                        </Box>
                        {message.type === 'user' ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: 'flex-end',
                              marginRight: '0',
                              marginLeft: 'auto',
                              marginTop: '10px'
                            }}
                          >
                            <MyAvatar />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "42px",
                              height: "42px",
                              padding: '10px',
                              backgroundColor: "#3EB54B",
                              color: "white",
                              display: "flex",
                              marginRight: 'auto',
                              marginLeft: '0',
                              marginTop: '10px'
                            }}
                          >
                            <Image src="/assets/icons/chatbot.png" alt="AI Chat Bot" />
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
              <div ref={messagesEndRef} />
            </List>
          </Box>
          <Box
            display="flex"
            p={2}
            sx={{
              backgroundColor: "white",
              borderBottomRightRadius: '15px',
              borderBottomLeftRadius: '15px'
            }}
            gap={1}
          >
            <TextField
              variant="outlined"
              placeholder="Type a message..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={handleKeyPress}
              sx={{
                bgcolor: "white",
                flexGrow: 1,
                borderRadius: "24px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                },
              }}
            />
            <IconButton
              color="primary"
              onClick={sendMessage}
              sx={{
                color: "white",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                backgroundColor: "#3EB54B",
                "&:hover": {
                  backgroundColor: "#007B55",
                },
              }}
            >
              <Image src="/assets/icons/send.png" alt="send icon" />
            </IconButton>
          </Box>
        </Box>
      </Collapse>
    </Container>
  );
}

export default ChatBot;
