import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'
import { dispatch } from '../store'

// Slice to fetch User Detail

const initialState = {
    isLoading: true,
    agent: null,
    error: null,
    success: null
}

const slice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        createAgentData(state, action) {
            state.agent = action.payload;
            localStorage.setItem('agent', JSON.stringify(action.payload));
        },
    }
});

export default slice.reducer;
export const { actions } = slice;

export function createAgent(data) {
    return (dispatch) => {
        dispatch(actions.createAgentData(data));
    }
}