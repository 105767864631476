import BlogCard from "src/components/cards/BlogCard"
import { Stack } from "@mui/material"

export default function RecentBlogs() {
    const blogs = [
        {
            imageUrl: '/assets/blog/r1.png',
            topic: 'Tech',
            createdAt: '2 hours ago',
            title: "How to Reclaim Your Time and Cut Costs with Omodore’s AI Assistant",
            content: "Looking to streamline your business operations and cut down on expenses? Omodore’s AI assistant provides powerful tools to automate routine tasks and manage communication, allowing you to reclaim valuable time and significantly reduce costs.",
            visitors: 15045,
            section: 'recent',
            header: "Managing the many aspects of a business can be a challenging task in today’s fast-paced environment. With the advancement of artificial intelligence (AI), businesses now have access to powerful tools that can significantly streamline operations, enhance productivity, and improve overall management. These AI tools are designed to handle repetitive tasks, organize workflows, manage communications, and provide insights, allowing business leaders to focus on strategic decision-making and growth. In this article, we’ll explore some of the best AI tools for business management in 2024. These tools are crafted to help you stay ahead in the competitive landscape, ensuring that your business operates efficiently and effectively.",
            details: [
                {
                    topic: "1. Voicenotes:",
                    desc: "AI Note Taker Voicenotes is an AI-powered transcription and note-taking tool that automatically transcribes voice memos and generates concise summaries and action items. With Voicenotes, businesses can save time and ensure that important information and decisions are captured accurately and efficiently.",
                    features: [
                        "• Accurate, real-time transcription of your voice memos",
                        "• Automated summary generation with key topics and action items Explore how Voicenotes can improve your note-taking: Visit Voicenotes."
                    ]
                },
                {
                    topic: "2. Zapier:",
                    desc: "Automate Workflows with AI-Powered Integrations Zapier is an automation platform that connects over 5,000 apps and services, enabling businesses to automate repetitive tasks and streamline workflows across their entire tech stack. Zapier's AI-powered tools allow businesses to create complex, multi-step automations without any coding required.",
                    features: [
                        "• Intuitive, drag-and-drop automation builder",
                        "• AI-powered recommendations for automations based on app usage",
                        "• Customizable triggers, actions, and filters for granular control",
                        "• Robust security and compliance features",
                    ]
                },
                {
                    topic: "3. Omodore:",
                    desc: "Enhance Customer Service Omodore is an innovative AI-driven platform that enhances customer service through advanced chatbots and call-assistants. It’s designed to streamline interactions, manage inquiries, and optimize customer support processes. Omodore’s AI technologies help businesses deliver timely and effective responses, improving overall customer satisfaction and engagement.",
                    features: [
                        "• AI-powered chatbots for 24/7 customer support",
                        "• Call-assistants to handle routine inquiries",
                        "• Integration with various CRM systems for seamless operations",
                        "• Analytics and reporting tools for performance monitoring",
                        "Learn more about how Omodore can transform your customer service: Explore Omodore.",
                    ]
                },
                {
                    topic: "4. SaneBox:",
                    desc: "Smart Email Management SaneBox scans your inbox to understand your communication patterns and helps you delete unwanted emails, prioritize important messages, and add tags to keep things organized. This tool is perfect for managing your inbox more efficiently and ensuring you never miss important emails.",
                    features: [
                        "• Intelligent email filtering and organization",
                        "• Priority inbox for important messages",
                        "• Email snoozing and follow-up reminders",
                        "• Deep integration with major email services",
                        "Improve your email management with SaneBox: Visit SaneBox.",
                    ]
                },
                {
                    topic: "5. Mailbutler:",
                    desc: "AI-Powered Email Assistant Mailbutler integrates with Gmail, Apple Mail, and Outlook to provide a suite of generative AI tools directly in your email compose window. Features like Smart Compose, Respond, Summarize, and Improve help you manage your emails more effectively by extracting contact information and organizing tasks.",
                    features: [
                        "• AI-powered email composition and response tools",
                        "• Task management and contact extraction",
                        "• Email scheduling and tracking",
                        "• Integration with popular email clients",
                        "Streamline your email tasks with Mailbutler: Explore Mailbutler",
                    ]
                },
                {
                    topic: "6. Decktopus:",
                    desc: "AI-Powered Presentation Builder Decktopus simplifies the creation of presentations. Enter the topic, target audience, and objective, and Decktopus generates a complete presentation with text and slides. It’s great for starting with a solid first draft and honing it into a powerful final version.",
                    features: [
                        "• AI-generated presentation templates",
                        "• Customizable slides and content",
                        "• Interactive elements and media integration",
                        "• User-friendly interface for quick edits",
                        "Create engaging presentations with Decktopus: Try Decktopus.",
                    ]
                },
                {
                    topic: "7. Notion AI:",
                    desc: "Organize and Retrieve Knowledge Notion AI enhances Notion's powerful workspace by generating answers based on your data, making it easier to retrieve important information recorded in meetings or documents. This AI tool helps ensure nothing falls through the cracks.",
                    features: [
                        "• AI-powered Q&A based on your data",
                        "• Knowledge management and retrieval",
                        "• Integration with various productivity tools",
                        "• Customizable workspace and templates",
                        "Automate Notion further with Zapier: Learn how.",
                    ]
                },
                {
                    topic: "8. Asana: ",
                    desc: "Advanced Project Management Asana is a project management heavyweight with AI-powered features like Smart Goals, project risk identification, and workflow optimization. It provides insights and answers related to your projects, helping you gain more visibility and control.",
                    features: [
                        "• AI-driven project and task management",
                        "• Smart goal setting and tracking",
                        "• Risk identification and mitigation",
                        "• Detailed project analytics and reporting",
                        "Automate Asana for even more efficiency: Learn how",
                    ]
                },
                {
                    topic: "9. Fireflies: ",
                    desc: "AI Meeting Transcriptions Fireflies transcribes all your meetings, tracking conversation topics along the way. Its bot, Fred, can summarize the meeting's contents, generate text, and search through your history to meet your query, making it a valuable tool for keeping track of discussions.",
                    features: [
                        "• Accurate meeting transcriptions",
                        "• AI-generated meeting summaries",
                        "• Searchable conversation history",
                        "• Integration with popular conferencing tools",
                        "Connect Fireflies with Zapier: Learn how.",
                    ]
                },
                {
                    topic: "10. Clockwise: ",
                    desc: "Smart Time Management Clockwise helps teams manage their schedules by creating an ideal workday balance of work, breaks, and meetings. It promises to save you one hour per week and provides settings to customize your schedule to fit your needs.",
                    features: [
                        "• AI-driven schedule optimization",
                        "• Automatic time blocking for focused work",
                        "• Team coordination and calendar syncing",
                        "• Customizable workday settings Optimize your team's schedule with Clockwise: Explore Clockwise.",
                    ]
                },
            ],
            footer: "Embracing AI tools for business management can dramatically improve efficiency, save time, and enhance the quality of your operations. From managing emails and automating workflows to creating presentations and organizing knowledge, these AI tools are essential for staying competitive in 2024. By integrating these tools into your daily operations, you can ensure that your business runs smoothly, allowing you to focus on strategic growth and innovation. Explore these tools, integrate them into your workflow, and experience the transformative power of AI in your business management practices. By staying updated with the latest AI technologies, you can maintain a competitive edge and drive your business towards a successful future.",
        },
        {
            imageUrl: '/assets/blog/r2.png',
            topic: 'Tech',
            createdAt: '5 hours ago',
            title: "Unlock Your Team's Potential: Top Tips for Motivation and Productivity",
            content: "Struggling to keep your team motivated and productive in a fast-paced business environment? Keeping your team engaged is crucial for achieving business success.",
            visitors: 28872,
            section: 'recent',
            header: "Managing the many aspects of a business can be a challenging task in today’s fast-paced environment. With the advancement of artificial intelligence (AI), businesses now have access to powerful tools that can significantly streamline operations, enhance productivity, and improve overall management. These AI tools are designed to handle repetitive tasks, organize workflows, manage communications, and provide insights, allowing business leaders to focus on strategic decision-making and growth. In this article, we’ll explore some of the best AI tools for business management in 2024. These tools are crafted to help you stay ahead in the competitive landscape, ensuring that your business operates efficiently and effectively.",
            details: [
                {
                    topic: "1. Voicenotes:",
                    desc: "AI Note Taker Voicenotes is an AI-powered transcription and note-taking tool that automatically transcribes voice memos and generates concise summaries and action items. With Voicenotes, businesses can save time and ensure that important information and decisions are captured accurately and efficiently.",
                    features: [
                        "• Accurate, real-time transcription of your voice memos",
                        "• Automated summary generation with key topics and action items Explore how Voicenotes can improve your note-taking: Visit Voicenotes."
                    ]
                },
                {
                    topic: "2. Zapier:",
                    desc: "Automate Workflows with AI-Powered Integrations Zapier is an automation platform that connects over 5,000 apps and services, enabling businesses to automate repetitive tasks and streamline workflows across their entire tech stack. Zapier's AI-powered tools allow businesses to create complex, multi-step automations without any coding required.",
                    features: [
                        "• Intuitive, drag-and-drop automation builder",
                        "• AI-powered recommendations for automations based on app usage",
                        "• Customizable triggers, actions, and filters for granular control",
                        "• Robust security and compliance features",
                    ]
                },
                {
                    topic: "3. Omodore:",
                    desc: "Enhance Customer Service Omodore is an innovative AI-driven platform that enhances customer service through advanced chatbots and call-assistants. It’s designed to streamline interactions, manage inquiries, and optimize customer support processes. Omodore’s AI technologies help businesses deliver timely and effective responses, improving overall customer satisfaction and engagement.",
                    features: [
                        "• AI-powered chatbots for 24/7 customer support",
                        "• Call-assistants to handle routine inquiries",
                        "• Integration with various CRM systems for seamless operations",
                        "• Analytics and reporting tools for performance monitoring",
                        "Learn more about how Omodore can transform your customer service: Explore Omodore.",
                    ]
                },
                {
                    topic: "4. SaneBox:",
                    desc: "Smart Email Management SaneBox scans your inbox to understand your communication patterns and helps you delete unwanted emails, prioritize important messages, and add tags to keep things organized. This tool is perfect for managing your inbox more efficiently and ensuring you never miss important emails.",
                    features: [
                        "• Intelligent email filtering and organization",
                        "• Priority inbox for important messages",
                        "• Email snoozing and follow-up reminders",
                        "• Deep integration with major email services",
                        "Improve your email management with SaneBox: Visit SaneBox.",
                    ]
                },
                {
                    topic: "5. Mailbutler:",
                    desc: "AI-Powered Email Assistant Mailbutler integrates with Gmail, Apple Mail, and Outlook to provide a suite of generative AI tools directly in your email compose window. Features like Smart Compose, Respond, Summarize, and Improve help you manage your emails more effectively by extracting contact information and organizing tasks.",
                    features: [
                        "• AI-powered email composition and response tools",
                        "• Task management and contact extraction",
                        "• Email scheduling and tracking",
                        "• Integration with popular email clients",
                        "Streamline your email tasks with Mailbutler: Explore Mailbutler",
                    ]
                },
                {
                    topic: "6. Decktopus:",
                    desc: "AI-Powered Presentation Builder Decktopus simplifies the creation of presentations. Enter the topic, target audience, and objective, and Decktopus generates a complete presentation with text and slides. It’s great for starting with a solid first draft and honing it into a powerful final version.",
                    features: [
                        "• AI-generated presentation templates",
                        "• Customizable slides and content",
                        "• Interactive elements and media integration",
                        "• User-friendly interface for quick edits",
                        "Create engaging presentations with Decktopus: Try Decktopus.",
                    ]
                },
                {
                    topic: "7. Notion AI:",
                    desc: "Organize and Retrieve Knowledge Notion AI enhances Notion's powerful workspace by generating answers based on your data, making it easier to retrieve important information recorded in meetings or documents. This AI tool helps ensure nothing falls through the cracks.",
                    features: [
                        "• AI-powered Q&A based on your data",
                        "• Knowledge management and retrieval",
                        "• Integration with various productivity tools",
                        "• Customizable workspace and templates",
                        "Automate Notion further with Zapier: Learn how.",
                    ]
                },
                {
                    topic: "8. Asana: ",
                    desc: "Advanced Project Management Asana is a project management heavyweight with AI-powered features like Smart Goals, project risk identification, and workflow optimization. It provides insights and answers related to your projects, helping you gain more visibility and control.",
                    features: [
                        "• AI-driven project and task management",
                        "• Smart goal setting and tracking",
                        "• Risk identification and mitigation",
                        "• Detailed project analytics and reporting",
                        "Automate Asana for even more efficiency: Learn how",
                    ]
                },
                {
                    topic: "9. Fireflies: ",
                    desc: "AI Meeting Transcriptions Fireflies transcribes all your meetings, tracking conversation topics along the way. Its bot, Fred, can summarize the meeting's contents, generate text, and search through your history to meet your query, making it a valuable tool for keeping track of discussions.",
                    features: [
                        "• Accurate meeting transcriptions",
                        "• AI-generated meeting summaries",
                        "• Searchable conversation history",
                        "• Integration with popular conferencing tools",
                        "Connect Fireflies with Zapier: Learn how.",
                    ]
                },
                {
                    topic: "10. Clockwise: ",
                    desc: "Smart Time Management Clockwise helps teams manage their schedules by creating an ideal workday balance of work, breaks, and meetings. It promises to save you one hour per week and provides settings to customize your schedule to fit your needs.",
                    features: [
                        "• AI-driven schedule optimization",
                        "• Automatic time blocking for focused work",
                        "• Team coordination and calendar syncing",
                        "• Customizable workday settings Optimize your team's schedule with Clockwise: Explore Clockwise.",
                    ]
                },
            ],
            footer: "Embracing AI tools for business management can dramatically improve efficiency, save time, and enhance the quality of your operations. From managing emails and automating workflows to creating presentations and organizing knowledge, these AI tools are essential for staying competitive in 2024. By integrating these tools into your daily operations, you can ensure that your business runs smoothly, allowing you to focus on strategic growth and innovation. Explore these tools, integrate them into your workflow, and experience the transformative power of AI in your business management practices. By staying updated with the latest AI technologies, you can maintain a competitive edge and drive your business towards a successful future.",
        },
    ]
    return (
        <Stack>
            {
                blogs.map((blog, index) => (
                    <BlogCard blog={blog} key={`blog-${index}`} />
                ))
            }
        </Stack>
    )
}