import { useTheme, useMediaQuery, Grid } from "@mui/material";
import Page from "src/components/Page";
import MainSection from "./MainSection";
import RecentBlogs from "./Recent";

export default function BlogPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Page title="Omodore Blog Page">
            <Grid container sx={{ px: { xs: 1, sm: 10 } }}>
                <Grid item xs={12} sm={8}>
                    <MainSection />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <RecentBlogs />
                </Grid>
            </Grid>
        </Page>
    )
}