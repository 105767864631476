import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'src/redux/slices/user';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

export default function RecordingsEmpty({ setOpenNewKnowledgeBaseModal }) {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={3}
            alignItems={'center'}
            sx={{
                borderRadius: '10px',
                mt: 6,
                height: '80%',
                py: 3
            }}
        >
            <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M104.5 87.0674C104.5 88.7774 104.12 90.5349 103.312 92.2449C102.505 93.9549 101.46 95.5699 100.083 97.0899C97.755 99.6549 95.19 101.507 92.2925 102.695C89.4425 103.882 86.3075 104.5 82.9825 104.5C78.1375 104.5 72.96 103.36 67.4975 101.032C62.035 98.7049 56.525 95.5699 51.11 91.6274C48.355 89.5849 45.6475 87.4949 43.035 85.2149L58.52 69.7299C58.5675 69.7299 58.5675 69.7299 58.615 69.7774C61.085 71.8674 63.1275 73.2924 64.7425 74.1474C64.98 74.2424 65.265 74.3849 65.5975 74.5274C65.9775 74.6699 66.3575 74.7174 66.785 74.7174C67.5925 74.7174 68.21 74.4324 68.7325 73.9099L72.3425 70.3474C73.53 69.1599 74.67 68.2574 75.7625 67.6874C76.855 67.0224 77.9475 66.6899 79.135 66.6899C80.0375 66.6899 80.9875 66.8799 82.0325 67.3074C83.0775 67.7349 84.17 68.3524 85.3575 69.1599L101.128 80.3224C102.363 81.1774 103.218 82.1749 103.74 83.3624C104.215 84.5499 104.5 85.7374 104.5 87.0674Z" fill="#39B54A" />
                <path opacity="0.4" d="M51.11 62.89L35.625 78.375C35.5775 78.375 35.5775 78.375 35.53 78.3275C30.6375 73.3875 26.22 68.21 22.23 62.795C18.3825 57.475 15.295 52.1075 13.015 46.835C12.9675 46.74 12.9675 46.6925 12.92 46.5975C10.64 41.1825 9.5 36.005 9.5 31.065C9.5 27.835 10.07 24.7475 11.21 21.8975C12.16 19.475 13.585 17.195 15.5325 15.1525C15.865 14.7725 16.245 14.345 16.6725 13.965C17.4325 13.205 18.1925 12.54 19 12.0175C19.0475 12.0175 19.0475 12.0175 19.0475 12.0175C21.4225 10.3075 23.94 9.5 26.6 9.5C27.93 9.5 29.26 9.785 30.4475 10.355C31.5875 10.8775 32.585 11.6375 33.3925 12.73C33.4875 12.825 33.535 12.92 33.63 13.015L44.65 28.5475C45.505 29.735 46.1225 30.8275 46.55 31.8725C46.9775 32.87 47.215 33.8675 47.215 34.77C47.215 35.91 46.8825 37.05 46.2175 38.1425C45.6 39.235 44.6975 40.375 43.5575 41.515L39.9475 45.2675C39.425 45.79 39.1875 46.4075 39.1875 47.1675C39.1875 47.5475 39.235 47.88 39.33 48.26C39.4725 48.64 39.615 48.925 39.71 49.21C40.565 50.7775 42.0375 52.82 44.1275 55.29C46.265 57.76 48.545 60.2775 51.015 62.795C51.0625 62.8425 51.0625 62.8425 51.11 62.89Z" fill="#39B54A" />
                <path d="M103.408 10.5924C101.983 9.16743 99.655 9.16743 98.23 10.5924L10.5925 98.2774C9.1675 99.7024 9.1675 102.03 10.5925 103.455C11.305 104.12 12.2075 104.5 13.1575 104.5C14.1075 104.5 15.01 104.12 15.7225 103.407L103.408 15.7224C104.88 14.2974 104.88 12.0174 103.408 10.5924Z" fill="#39B54A" />
            </svg>


            <Typography variant='h3'>
                No Call Recordings yet !
            </Typography>
            <Typography sx={{ maxWidth: '580px', textAlign: 'center' }}>
                The recordings of your agents test and live calls will go
                here. As soon as your calls end, here is where you will be
                able to listen to the recording, download them, and more.
            </Typography>
        </Box>
    )
}