import * as React from 'react';
import { Card, CardMedia, Stack, Typography, IconButton } from '@mui/material';
import { FastRewindRounded } from '@mui/icons-material';
import { FastForwardRounded } from '@mui/icons-material';
import { PlayArrowRounded } from '@mui/icons-material';
import { PauseRounded } from '@mui/icons-material';
import { LoopRounded } from '@mui/icons-material';
import StopRoundedIcon from '@mui/icons-material/StopRounded';

export default function AudioPlayer({ record, audioController }) {
    const [paused, setPaused] = React.useState(true);
    const [repeat, setRepeat] = React.useState(false);
    const [timeFlow, setTimeFlow] = React.useState('Play the record');

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeFlow(getDuration(audioController.getCurrentTime()));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const getTimeFormat = (time) => {
        return time < 10 ? '0' + time : time;
    }

    const getDuration = (stamp) => {
        const h = Math.floor(stamp / 3600);
        const m = Math.floor(stamp % 3600 / 60);
        const s = Math.floor(stamp % 60);
        return getTimeFormat(h) + ' : ' + getTimeFormat(m) + ' : ' + getTimeFormat(s);
    }

    return (
        <Card
            variant="outlined"
            sx={{
                p: 2,
                width: { xs: '100%', sm: 'auto' },
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                gap: 2,
            }}
        >
            <CardMedia
                component="img"
                width="100"
                height="100"
                alt="Contemplative Reptile album cover"
                src="/assets/audio_bg.jpg"
                sx={{
                    width: { xs: '100%', sm: 100 },
                }}
            />
            <Stack direction="column" alignItems="center" spacing={1} useFlexGap>
                <div>
                    <Typography color="text.primary" fontWeight="semiBold">
                        {
                            new Date(record.date_created).toLocaleString('en-GB', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit'
                            })
                        }
                    </Typography>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        fontWeight="medium"
                        textAlign="center"
                        justifyContent={'center'}
                        display={'flex'}
                        sx={{ width: '100%' }}
                    >
                        {
                            timeFlow
                        }
                    </Typography>
                </div>
                <Stack direction="row" alignItems="center" spacing={1} useFlexGap>
                    <IconButton
                        aria-label="Shuffle"
                        onClick={() => {
                            setPaused(true);
                            audioController.stop();
                        }}
                    >
                        <StopRoundedIcon />
                    </IconButton>
                    <IconButton a
                        ria-label="Fast rewind"
                        onClick={() => {
                            audioController.fastBackward(5);
                        }}
                    >
                        <FastRewindRounded />
                    </IconButton>
                    <IconButton
                        aria-label={paused ? 'Play music' : 'Pause music'}
                        onClick={() => {
                            if (paused) {
                                audioController.getCurrentTime() ?
                                    audioController.resume(record) :
                                    audioController.play(record)
                            } else {
                                audioController.pause(record)

                            }
                            setPaused(!paused);
                        }
                        }
                        sx={{ mx: 1 }}
                    >
                        {paused ? <PlayArrowRounded /> : <PauseRounded />}
                    </IconButton>
                    <IconButton
                        aria-label="Fast forward"
                        onClick={() => {
                            audioController.fastForward(5);
                        }}
                    >
                        <FastForwardRounded />
                    </IconButton>
                    <IconButton
                        aria-label="Loop music"
                        onClick={
                            () => {
                                setRepeat(!repeat);
                                if (!repeat) {
                                    audioController.repeat();
                                } else {
                                    audioController.isRepeating = false;
                                }
                            }
                        }
                    >
                        <LoopRounded />
                    </IconButton>
                </Stack>
            </Stack>
        </Card>
    )
}