import { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Alert,
  Container,
  Typography,
  Card,
  Grid,
  Avatar
} from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import Page from "../../components/Page";
// sections
import { LoginForm } from "../../sections/auth/login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { signInWithGooglePopup } from "src/firebase";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  alignItems: 'center',
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(15, 3, 2, 3),
  backgroundColor: '#ebebeb',
}));

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const brandings = [
  {
    image: '/assets/illustrations/landing (1).png',
    desc: 'Our mission is to empower organizations with innovative tools that boost efficiency, improve customer experiences and drive growth.'
  },
  {
    image: '/assets/illustrations/landing (2).png',
    desc: 'Omodore offers customer service solutions such as AI cal-assistants, chatbots and other innovative technologies.'
  },
  {
    image: '/assets/illustrations/landing (3).png',
    desc: 'Whether in healthcare, hospitality, e-commerce, telecommunications, financial services, manufacturing or education, our AI technologies help you stay ahead in a fast-changing marketplace.'
  },
  {
    image: '/assets/illustrations/landing (4).png',
    desc: 'Our tools streamline operations, reduce costs and optimize resources. Our tools deliver quick, precise responses, enhancing the quality of interactions.'
  },
  {
    image: '/assets/illustrations/landing (5).png',
    desc: 'Experience the future of AI with Omodore. Explore our platform, discover our solutions and transform your business today.'
  },
];

// ----------------------------------------------------------------------

export default function Login() {
  const { login } = useAuth();
  const [error, setError] = useState("");

  const handleGoogleAuth = async () => {
    const response = await signInWithGooglePopup();
    const email = response._tokenResponse.email;

    try {
      await login(email, email);
      setError("");
    } catch (err) {
      console.log("error is", err);
      setError("No account with this google account. Please register first.");
    }
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h1" sx={{ display: "none" }}>
              Omodore Login Page
            </Typography>
          </Stack>
        </HeaderStyle>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SectionStyle>
              <Typography
                variant="h1"
                sx={{ px: 5, mt: 10, fontSize: { xs: "20px", md: "25px" }, letterSpacing: '0px' }}
              >
                Welcome to Omodore AI.
              </Typography>
              <Typography
                variant="h1"
                sx={{ px: 5, mb: 5, fontSize: { xs: "20px", md: "25px" }, letterSpacing: '0px' }}
              >
                AI-powered assistant to improve your customer service.
              </Typography>
              <Box sx={{ mx: '20px' }}>
                <Box
                  component="video"
                  controls
                  loop
                  autoPlay
                  muted
                  src="/assets/videos/omodore.mp4"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '10px',
                  }}
                />

                <Stack spacing={4} sx={{ my: 6 }}>
                  {
                    brandings.map((branding, index) => (
                      <Stack key={`branding-${index}`} direction='row' spacing={3} alignItems='center'>
                        <Avatar
                          src={branding.image}
                          alt="branding"
                          sx={{ height: 56, width: 56 }}
                        />
                        <Typography sx={{ fontWeight: '600', fontSize: { xs: "14px", md: "16px" } }}>{branding.desc}</Typography>
                      </Stack>
                    ))
                  }
                </Stack>
              </Box>
            </SectionStyle>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Container maxWidth="sm">
              <ContentStyle>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      Sign in
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Enter your details below.
                    </Typography>
                  </Box>
                </Stack>

                <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
                  <GoogleLoginButton onClick={(e) => handleGoogleAuth()} />
                </Box>

                {error && (
                  <Alert severity="error" sx={{ mb: "20px", mt: "-20px" }}>
                    {error}
                  </Alert>
                )}

                <LoginForm />
              </ContentStyle>
            </Container>
          </Grid>
        </Grid>
      </RootStyle>
    </Page>
  );
}
