import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Stack, Typography, Select, MenuItem, useMediaQuery, IconButton, Drawer } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountPopover from "./AccountPopover";

export default function DashboardHeader() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector((state) => state?.user);
  const [lang, setLang] = useState('en');

  return (
    <Stack
      sx={{
        px: 5,
        py: 3,
        justifyContent: 'space-between',
        flexDirection: 'row',
        boxShadow: '0px 4px 24px 0px #0000001A',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ffffff',
        zIndex: 1002,
      }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', cursor: 'pointer' }} onClick={e => navigate('/')}>
        <img
          src="/logo/home.png"
          style={{ maxWidth: '140px', objectFit: 'contain' }}
          alt="Home Page Logo"
        />
        <Stack sx={{ justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '10px', lineHeight: '12px' }}>Chat Smarter</Typography>
          <Typography sx={{ fontSize: '10px', lineHeight: '12px' }}>Connect Faster</Typography>
        </Stack>
      </Stack>

      {
        isMobile
          ? <MobileMenu />
          :
          <>
            <Stack direction="row" spacing={2} >
              <Button sx={{
                color: '#000',
                "&:hover": {
                  backgroundColor: '#fff',
                  color: '#000'
                },
                fontSize: '14px',
                fontWeight: '400'
              }} onClick={() => navigate('/features')}>Features</Button>
              <Button sx={{
                color: '#000',
                "&:hover": {
                  backgroundColor: '#fff',
                  color: '#000'
                },
                fontSize: '14px',
                fontWeight: '400'
              }} onClick={() => navigate('/help')}>How to</Button>
              <Button sx={{
                color: '#000',
                "&:hover": {
                  backgroundColor: '#fff',
                  color: '#000'
                },
                fontSize: '14px',
                fontWeight: '400'
              }} onClick={() => navigate('/pricing')}>Pricing</Button>
              <Button sx={{
                color: '#000',
                "&:hover": {
                  backgroundColor: '#fff',
                  color: '#000'
                },
                fontSize: '14px',
                fontWeight: '400'
              }} onClick={() => navigate('/about-us')}>About Us</Button>
              <Button sx={{
                color: '#000',
                "&:hover": {
                  backgroundColor: '#fff',
                  color: '#000'
                },
                fontSize: '14px',
                fontWeight: '400'
              }} onClick={() => navigate('/contact-us')}>Contact</Button>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ height: '42px' }}>
              <Select defaultValue="en" value={lang} onChange={e => setLang(e.target.value)}>
                <MenuItem value="en">En</MenuItem>
                <MenuItem value="es">Es</MenuItem>
                <MenuItem value="pt">Pt</MenuItem>
              </Select>
              {
                user ?
                  <AccountPopover user={user} />
                  : <>
                    <SignUpButton />
                    <LoginButton />
                  </>
              }
            </Stack>
          </>
      }


    </Stack>
  );
}

export const MobileMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
      >
        <IconButton sx={{ mr: 0, ml: 'auto' }} onClick={e => setIsDrawerOpen(false)}>
          <CloseIcon />
        </IconButton>
        <Stack direction="column" spacing={2} sx={{ margin: 2, mt: 10 }}>
          <LoginButton />
          <SignUpButton />
        </Stack>
      </Drawer>
    </div>
  );
}

export const SignUpButton = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/auth/register');
  }

  return (
    <Button
      variant="contained"
      onClick={handleSignUp}
      sx={{ width: '200px' }}
    >
      Get Started for Free
    </Button>
  );
}

export const LoginButton = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/auth/login');
  }

  return (
    <Button variant="outlined" onClick={handleLogin}>
      Sign In
    </Button>
  );
}
