import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
  Paper,
} from "@mui/material";
import React from "react";
import Page from "src/components/Page";

const TermsAndConditionsPage = () => {
  return (
    <Page title="Privacy-Interpretation" height={"100%"}>
      <Container maxWidth="lg">
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            border: "1px solid rgba(65,65,65, 0.13)",
            borderRadius: "10px",
            p: 3,
          }}
        >
          <Box>
            <Typography variant="h4">Terms and Conditions</Typography>
            <Stack sx={{ ml: 3, gap: 2 }}>
              <Typography>Welcome to www.omodore.com!</Typography>
              <Typography>
                These terms and conditions outline the rules and regulations for
                the use of Omodore's Website, located at{" "}
                <a href="https://omodore.com/">https://omodore.com/</a>.
              </Typography>
              <Typography>
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use www.omodore.com if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </Typography>
              <Typography>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company's terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client's needs in respect of
                provision of the Company's stated services, in accordance with
                and subject to, prevailing law of ae. Any use of the above
                terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">Cookies</Typography>
            <Stack sx={{ ml: 3, gap: 2 }}>
              <Typography>
                We employ the use of cookies. By accessing www.omodore.com, you
                agreed to use cookies in agreement with the Omodore's Privacy
                Policy.
              </Typography>

              <Typography>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">License</Typography>
            <Stack sx={{ ml: 3 }}>
              <Typography>
                Unless otherwise stated, Omodore and/or its licensors own the
                intellectual property rights for all material on
                www.omodore.com. All intellectual property rights are reserved.
                You may access this from www.omodore.com for your own personal
                use subjected to restrictions set in these terms and conditions.
              </Typography>

              <List>
                <ListItem>
                  <ListItemText primary="Republish material from www.omodore.com" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Sell, rent or sub-license material from www.omodore.com" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Reproduce, duplicate or copy material from www.omodore.com" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Redistribute content from www.omodore.com" />
                </ListItem>
              </List>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">Hyperlinking to our Content</Typography>
            <Stack sx={{ ml: 3 }}>
              <Typography>
                The following organizations may link to our Website without
                prior written approval:
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Government agencies;" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Search engines;" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="News organizations;" />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site."
                  />
                </ListItem>
              </List>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">iFrames</Typography>
            <Stack sx={{ ml: 3, gap: 2 }}>
              <Typography>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Typography variant="h4">Content Liability</Typography>
            <Stack sx={{ ml: 3, gap: 2 }}>
              <Typography>
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Typography variant="h4">Disclaimer</Typography>
            <Stack sx={{ ml: 3 }}>
              <Typography>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </Typography>

              <List>
                <ListItem>
                  <ListItemText primary="limit or exclude our or your liability for death or personal injury;" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="limit or exclude our or your liability for fraud or fraudulent misrepresentation;" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="limit any of our or your liabilities in any way that is not permitted under applicable law; or" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="exclude any of our or your liabilities that may not be excluded under applicable law." />
                </ListItem>
              </List>
              <Typography>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </Typography>
              <Typography>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </Typography>
            </Stack>
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default TermsAndConditionsPage;
