import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'src/redux/slices/user';

export default function AgentEmpty({ setOpenNewAgentModal }) {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={3}
            alignItems={'center'}
            border={'1px solid rgba(65, 65, 65, .13)'}
            sx={{
                borderRadius: '10px',
                mt: 6,
                height: '80%',
                py: 3
            }}
        >
            <svg width="118" height="130" viewBox="0 0 118 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M107.172 22.9923H76.4282L81.5098 11.6651C84.5907 11.6597 87.0857 9.1621 87.0857 6.08122C87.0857 3.00035 84.5853 0.5 81.5017 0.5C78.4182 0.5 75.9178 3.00035 75.9178 6.08392C75.9178 7.3908 76.3715 8.59237 77.1248 9.54283L71.0899 22.9923H43.7185L37.6836 9.54283C38.437 8.59237 38.8906 7.3908 38.8906 6.08392C38.8906 3.00035 36.3902 0.5 33.3067 0.5C30.2231 0.5 27.7227 3.00035 27.7227 6.08392C27.7227 9.1675 30.2177 11.6624 33.2985 11.6678L38.3802 22.995H10.8278C5.13862 22.9923 0.524048 27.6069 0.524048 33.2961V89.8644C0.524048 95.5563 5.13862 100.168 10.8278 100.168H91.9136C91.9136 100.168 94.3059 117.879 117.476 129.5V33.2961C117.476 27.6042 112.864 22.9923 107.172 22.9923ZM76.5119 80.9998H24.1207V41.672H93.8793V96.7282L76.5092 80.9971L76.5119 80.9998Z" fill="#39B54A" />
                <path d="M44.4718 50.9011C41.9958 50.9011 39.9895 54.9973 39.9895 60.052C39.9895 65.1066 41.9958 69.2028 44.4718 69.2028C46.9478 69.2028 48.9541 65.1066 48.9541 60.052C48.9541 54.9973 46.9478 50.9011 44.4718 50.9011Z" fill="#39B54A" />
                <path d="M71.657 50.9011C69.1809 50.9011 67.1747 54.9973 67.1747 60.052C67.1747 65.1066 69.1809 69.2028 71.657 69.2028C74.133 69.2028 76.1393 65.1066 76.1393 60.052C76.1393 54.9973 74.133 50.9011 71.657 50.9011Z" fill="#39B54A" />
            </svg>
            <Typography variant='h3'>
                <span>Welcome, </span>
                {user && user.name}
                <span> :)</span>
            </Typography>
            <Typography sx={{ maxWidth: '550px', textAlign: 'center' }}>
                To get Started, create your first AI agent by clicking the button below.
                Once you create your agent, you can place it on live calls in minutes
            </Typography>
            <Button
                variant='contained'
                startIcon={<BoltIcon />}
                onClick={e => setOpenNewAgentModal(true)}
            >
                Create AI Agent
            </Button>
        </Box>
    )
}