// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_DOCS } from '../../../routes/paths';
// assets
import { DocIllustration } from '../../../assets';
import { NavSectionVertical } from 'src/components/nav-section';
import SvgIconStyle from 'src/components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const ICONS = {
  blog: getIcon('ic_campaigns'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_contact'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_recording'),
  dashboard: getIcon('ic_share'),
  menuItem: getIcon('ic_menu_item'),
  global: getIcon('ic_global'),
  preferences: getIcon('ic_setting')
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'languages', path: "", icon: ICONS.global },
      //{ title: 'preferences', path: "", icon: ICONS.preferences },
    ]
  }

]

export default function NavbarDocs() {
  const { user } = useAuth();

  const { translate } = useLocales();

  return (
    <Stack spacing={3}
      sx={{
        pt: 5,
        pb: 2,
        flexShrink: 0,
      }}
    >
      <NavSectionVertical navConfig={navConfig} />
    </ Stack>
  );
}
