import * as React from 'react';
import { Typography, Box, Stack, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    maxHeight: '800px',
    p: 4,
};


export default function EditingModeModal({ openEditingModeModal, setOpenEditingModeModal, setOpenScriptModal, agentInfo, setAgentInfo }) {
    const handleClose = () => setOpenEditingModeModal(false);

    const handleSelectMode = () => {
        handleClose();
        setOpenScriptModal(true);
    }

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
            },
        }),
    );

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    return (
        <Modal
            open={openEditingModeModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                    Choose Your Editing Mode
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <RadioGroup
                        name="use-radio-group"
                        defaultValue="standard"
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'nowrap'
                        }}
                        onChange={e => { setAgentInfo({ ...agentInfo, editMode: e.target.value }) }}
                    >
                        <MyFormControlLabel
                            value="standard"
                            label={
                                <div>
                                    <h3>Standard (Recommended)</h3>
                                    <p>Standard mode allows you to launch Air faster and with more simplicity. It requires less time and technical skill. It is the recommended for the far majority of our users. If you don't know what to choose, choose this mode.</p>
                                </div>
                            }
                            control={<Radio sm={{ pt: 0 }} />}
                            sx={{ width: '50%', alignItems: 'flex-start', gap: '10px' }}
                        />
                        <MyFormControlLabel
                            value="advanced"
                            label={
                                <div>
                                    <h3>Advanced</h3>
                                    <p>Advanced mode allows you to edit Air's underlying prompt and model. This is for users with more technical context that want to test on a deeper level. Only recommended if you have experience prompting.</p>
                                </div>
                            }
                            control={<Radio sm={{ pt: 0 }} />}
                            sx={{ width: '50%', alignItems: 'flex-start', gap: '10px' }}
                        />
                    </RadioGroup>
                    <Stack sx={{ mt: 4 }}>
                        <Button
                            variant="outlined"
                            display={'flex'}
                            sx={{ ml: 'auto', mr: 0 }}
                            onClick={handleSelectMode}
                        >
                            Choose Selected Mode
                        </Button>
                    </Stack>
                </Typography>
            </Box>
        </Modal >
    )
}