import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { BarChart } from "@mui/x-charts";

export default function BasicChart({ data }) {
  const [chartType, setChartType] = React.useState("bar");
  const [withArea, setWithArea] = React.useState(false);

  const [barChartsParams, setBarChartsParams] = React.useState({
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: new Date().getFullYear().toString(),
      },
    ],
    xAxis: [
      {
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        scaleType: "band",
      },
    ],
    height: 400,
  });

  React.useEffect(() => {
    if (data) {
      setBarChartsParams((items) => ({
        ...items,
        series: [{ ...items.series[0], data: data }],
      }));
    }
  }, [data]);

  const handleChartType = (event, newChartType) => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  return (
    <Stack
      direction={{ xs: "column", xl: "row" }}
      spacing={1}
      sx={{ width: "100%" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={handleChartType}
          aria-label="chart type"
          fullWidth
        ></ToggleButtonGroup>
        {chartType === "bar" && (
          <BarChart
            {...barChartsParams}
            series={barChartsParams.series.map((series) => ({
              ...series,
            }))}
          />
        )}
      </Box>
    </Stack>
  );
}
