import * as React from "react";
import Page from "src/components/Page";
import axios from "src/utils/axios";
import { useNavigate } from "react-router";
import useSettings from "src/hooks/useSettings";
import {
    Button,
    Container,
    FormControl,
    Grid,
    Link,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

export default function HelpPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { themeStretch } = useSettings();

    return (
        <Page title="Help" height={"100%"}>
            <Container maxWidth={themeStretch ? false : "lg"}>
                <Paper
                    sx={{
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: "10px",
                        p: 3,
                    }}
                >
                    <Stack>
                        <Grid container sx={{
                            alignItems: 'center',
                            padding: 3,
                            background: '#F5F5F5',
                            borderRadius: '10px'
                        }}>
                            <Grid item xs={12} sm={8} sx={{ order: { xs: 2, sm: 1 } }}>
                                <Stack spacing={2}>
                                    <Typography variant="h3">
                                        Getting Stated with <span style={{ color: '#3EB54B' }}>Omodore</span>
                                    </Typography>
                                    <Typography sx={{ fontStyle: 'italic' }}>
                                        Welcome to the How-To page for Omodore! This guide will help you design a user-friendly and informative page to assist visitors in navigating and utilizing Omodore&apos;s features effectively. Below is a detailed description of the layout, appearance, and content for each section of the page.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', order: { xs: 1, sm: 2 } }}>
                                <img src="/logo/help.png" alt="Help Page Logo" />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            gap={2}
                            sx={{
                                alignItems: 'stretch',
                                padding: 3,
                                background: '#fff',
                                borderRadius: '10px',
                                paddingLeft: '0px',
                                paddingRight: '0',
                            }}>
                            <GroupComponent
                                imageUrl={listItems[0].imageUrl}
                                title={listItems[0].title}
                                content={listItems[0].content}
                            />
                            <GroupComponent
                                imageUrl={listItems[1].imageUrl}
                                title={listItems[1].title}
                                content={listItems[1].content}
                            />
                        </Grid>

                        <TitleComponent
                            title1={titleItems[0].title1}
                            title2={titleItems[0].title2}
                            content={titleItems[0].content}
                        />
                        <Grid
                            container
                            gap={2}
                            sx={{
                                alignItems: 'stretch',
                                padding: 3,
                                background: '#fff',
                                borderRadius: '10px',
                                paddingLeft: '0px',
                                paddingRight: '0'
                            }}>
                            <GroupComponent
                                imageUrl={listItems[2].imageUrl}
                                title={listItems[2].title}
                                content={listItems[2].content}
                            />
                            <GroupComponent
                                imageUrl={listItems[3].imageUrl}
                                title={listItems[3].title}
                                content={listItems[3].content}
                            />
                        </Grid>

                        <TitleComponent
                            title1={titleItems[1].title1}
                            title2={titleItems[1].title2}
                            content={titleItems[1].content}
                        />
                        <Grid
                            container
                            gap={2}
                            sx={{
                                alignItems: 'stretch',
                                padding: 3,
                                background: '#fff',
                                borderRadius: '10px',
                                paddingLeft: '0px',
                                paddingRight: '0'
                            }}>
                            <GroupComponent
                                imageUrl={listItems[4].imageUrl}
                                title={listItems[4].title}
                                content={listItems[4].content}
                            />
                            <GroupComponent
                                imageUrl={listItems[5].imageUrl}
                                title={listItems[5].title}
                                content={listItems[5].content}
                            />
                        </Grid>

                        <TitleComponent
                            title1={titleItems[2].title1}
                            title2={titleItems[2].title2}
                            content={titleItems[2].content}
                        />
                        <Grid
                            container
                            gap={2}
                            sx={{
                                alignItems: 'stretch',
                                padding: 3,
                                background: '#fff',
                                borderRadius: '10px',
                                paddingLeft: '0px',
                                paddingRight: '0'
                            }}>
                            <GroupComponent
                                imageUrl={listItems[6].imageUrl}
                                title={listItems[6].title}
                                content={listItems[6].content}
                            />
                            <GroupComponent
                                imageUrl={listItems[7].imageUrl}
                                title={listItems[7].title}
                                content={listItems[7].content}
                            />
                        </Grid>

                        <TitleComponent
                            title1={titleItems[3].title1}
                            title2={titleItems[3].title2}
                            content={titleItems[3].content}
                        />
                        <Grid
                            container
                            gap={2}
                            sx={{
                                alignItems: 'stretch',
                                padding: 3,
                                background: '#fff',
                                borderRadius: '10px',
                                paddingLeft: '0px',
                                paddingRight: '0'
                            }}>
                            <GroupComponent
                                imageUrl={listItems[8].imageUrl}
                                title={listItems[8].title}
                                content={listItems[8].content}
                            />
                            <GroupComponent
                                imageUrl={listItems[9].imageUrl}
                                title={listItems[9].title}
                                content={listItems[9].content}
                            />
                        </Grid>

                        <TitleComponent
                            title1={titleItems[4].title1}
                            title2={titleItems[4].title2}
                            content={titleItems[4].content}
                        />
                        <Stack direction='row' spacing={2} sx={{ mt: 3 }}>
                            <Stack direction='row' spacing={2} alignItems='center' sx={{
                                padding: '30px 40px',
                                border: '1px solid #EDEDED',
                                boxShadow: '0px 4px 7px 0px #0000001A',
                                borderRadius: '4px'
                            }}>
                                <img src="/assets/help/11.png" width='53px' />
                                <Link href="mailto:info@omodore.com"
                                    sx={{
                                        color: '#000',
                                        '&:hover': { textDecoration: "none" },
                                        fontSize: '13px',
                                        fontWeight: '700'
                                    }}>
                                    info@omodore.com
                                </Link>

                            </Stack>

                            <Stack direction='row' spacing={2} alignItems='center' sx={{
                                padding: '30px 40px',
                                border: '1px solid #EDEDED',
                                boxShadow: '0px 4px 7px 0px #0000001A',
                                borderRadius: '4px'
                            }}>
                                <img src="/assets/help/12.png" width='53px' />
                                <Typography sx={{
                                    color: '#000',
                                    '&:hover': { textDecoration: "none" },
                                    fontSize: '13px',
                                    fontWeight: '700'
                                }}>Available 24/7 for your convenience</Typography>
                            </Stack>

                        </Stack>
                    </Stack>
                </Paper>
            </Container>
        </Page >
    );
}

const listItems = [
    {
        imageUrl: '/assets/help/1.png',
        title: 'Step 1: Create Your Account',
        content: 'Follow our simple guide to set up your Omodore account. Learn how to enter your business details, customize your dashboard, and get ready to deploy AI- powered solutions.'
    },
    {
        imageUrl: '/assets/help/2.png',
        title: 'Step 2: Explore the Dashboard',
        content: 'Navigate through the intuitive dashboard to access various tools and features.Discover how to manage your AI agents, track analytics, and customize settings to fit your business needs.'
    },
    {
        imageUrl: '/assets/help/3.png',
        title: 'Choose from Preset Agents',
        content: 'Select from our range of preset agents, designed for different industries like customer service, healthcare, e-commerce, and more.Each agent comes pre - configured with industry - specific features to streamline your operations.'
    },
    {
        imageUrl: '/assets/help/4.png',
        title: 'Personalize Your Agent',
        content: "Learn how to adjust language settings, script responses, and integrate your brand's voice into your AI agent.This section covers everything from setting up automated greetings to configuring advanced response patterns."
    },
    {
        imageUrl: '/assets/help/5.png',
        title: 'Effective Scripting',
        content: 'Learn the art of crafting effective scripts that resonate with your audience. This includes tips on tone, language, and structuring responses to enhance customer engagement.'
    },
    {
        imageUrl: '/assets/help/6.png',
        title: 'Optimizing Agent Performance',
        content: 'Get insights on regular maintenance and updates to keep your AI agents performing at their best. Learn about training your agents on new data and refining their responses for better accuracy.'
    },
    {
        imageUrl: '/assets/help/7.png',
        title: 'Common Issues and Solutions',
        content: "A comprehensive list of common issues you might encounter, along with step-by-step solutions to resolve them. From login problems to configuring settings, we've got you covered."
    },
    {
        imageUrl: '/assets/help/8.png',
        title: 'Customer Support',
        content: 'Reach out to our dedicated customer support team for personalized assistance. Learn about the various support channels available, including live chat, email, and phone support.'
    },
    {
        imageUrl: '/assets/help/9.png',
        title: 'Overall Usage',
        content: 'View detailed statistics on how often your AI agents are used. Track engagement levels, user interactions, and the overall impact on your business operations.'
    },
    {
        imageUrl: '/assets/help/10.png',
        title: 'Successful Results',
        content: 'Review success metrics and outcomes achieved by your AI agents. See how well they are performing in terms of customer satisfaction, response accuracy, and operational efficiency.'
    },
];

const titleItems = [
    {
        title1: 'Customizing Your',
        title2: 'AI Agent',
        content: "Tailor your AI agents to align with your brand and meet specific business requirements. This section will guide you through the customization options available on Omodore."
    },
    {
        title1: 'Best Practices and',
        title2: 'Tips',
        content: "Maximize the effectiveness of your AI agents with best practices and expert tips."
    },
    {
        title1: 'Troubleshooting and',
        title2: 'Support',
        content: "Find solutions to common issues and learn how to get the support you need."
    },
    {
        title1: 'Overall Agent',
        title2: 'Insights',
        content: "Monitor the overall performance and success of your AI agents."
    },
    {
        title1: 'Contact',
        title2: 'Us',
        content: "We're here to help! For any inquiries or additional support, reach out to us via the contact information below."
    },
]

export const GroupComponent = ({ imageUrl, title, content }) => {
    const navigate = useNavigate();

    return (
        <Grid
            item
            container
            xs={12}
            sm={6}
            sx={{
                border: '1px solid #EDEDED',
                boxShadow: '0px 4px 7px 0px #0000001A',
                borderRadius: '4px',
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'scale(1.01)',
                    boxShadow: '0px 8px 15px 0px #00000033'
                },
                maxWidth: '49% !important',
                flexGrow: 1
            }}
            onClick={() => navigate('/agents')}
        >
            <Grid item xs={12} sm={4} alignItems='center' display='flex' justifyContent='center'>
                <img src={imageUrl} style={{ width: '126px' }} />
            </Grid>
            <Grid itme xs={12} sm={8} sx={{ padding: 2 }}>
                <Stack spacing={1}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '700', textAlign: { xs: 'center', sm: 'left' } }}>{title}</Typography>
                    <Typography sx={{ fontSize: '13px', textAlign: { xs: 'center', sm: 'left' } }}>
                        {content}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}

export const TitleComponent = ({ title1, title2, content }) => {
    return (
        <Grid
            container
            sx={{
                alignItems: 'center',
                padding: 3,
                background: '#F5F5F5',
                borderRadius: '10px',
                flexDirection: {
                    xs: 'column',
                    sm: 'row'
                }
            }}
        >
            <Grid item xs={12} sm={4} display='flex' justifyContent='center' >
                <Typography variant="h4" sx={{
                    align: {
                        xs: 'center',
                        sm: 'left'
                    }
                }} >
                    {title1} <span style={{ color: '#3EB54B' }}>{title2}</span>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={8} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Typography
                    sx={{
                        align: {
                            xs: 'center',
                            sm: 'left'
                        },
                        fontSize: '14px',
                        fontStyle: 'italic'
                    }}
                >
                    {content}
                </Typography>
            </Grid>
        </Grid>
    )
}
