import * as React from 'react';
import Page from 'src/components/Page';
import RecordingsEmpty from 'src/sections/recordings/empty';
import axios from 'src/utils/axios';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton } from '@mui/material';
import AudioPlayerModal from 'src/components/modals/recording/AudioPlayerModal';
import { Download } from '@mui/icons-material';

export default function RecordingsPage() {
    const [recordings, setRecordings] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modalShow, setModalShow] = React.useState(false);
    const [record, setRecord] = React.useState(null);

    React.useEffect(() => {
        axios
            .get('api/v1/agents/get_recordings/')
            .then(res => {
                setRecordings([...res.data.recordings]);
            })
            .catch(err => {
                console.log('Get Recording s Error!', err);
            });
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const headerSections = [
        'Date',
        'From',
        'To',
        'Duration',
        'Download'
    ]

    const sections = [
        'date_created',
        'caller_number',
        'callee_number',
        'duration',
    ]

    const getDuration = (stamp) => {
        const h = Math.floor(stamp / 3600);
        const m = Math.floor(stamp % 3600 / 60);
        const s = Math.floor(stamp % 60);
        return (h ? h + ' hour ' : '') + (m ? m + ' min ' : '') + (s ? s + ' sec ' : '');
    }

    const audioController = (() => {
        let audio = null;
        let blobUrl = null;
        let currentRecord = null;
        let isRepeating = false;

        const play = (record) => {
            currentRecord = record;

            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }

            const proxyUrl = `/api/v1/agents/play_recording/${record.call_sid}`;
            axios({
                method: 'get',
                url: proxyUrl,
                responseType: 'blob'
            })
                .then(response => {
                    const blob = response.data;
                    blobUrl = URL.createObjectURL(blob);

                    if (audio) {
                        audio.pause();  // Stop any current playing audio
                    }

                    // Create a new Audio object and play it
                    audio = new Audio(blobUrl);
                    audio.play()
                        .then(() => {
                            console.log("Audio is playing...");
                            setAudioRepeat();
                        })
                        .catch(e => console.error('Error playing audio:', e));
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });
        };

        const pause = () => {
            if (audio) {
                audio.pause();
            }
        };

        const resume = () => {
            if (audio) {
                audio.play();
            }
        };

        const stop = () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;  // Reset the audio to the beginning
            }
        };

        const fastForward = (seconds) => {
            if (audio && !isNaN(seconds)) {
                const newTime = audio.currentTime + seconds;
                audio.currentTime = Math.min(newTime, audio.duration);
            }
        };

        const fastBackward = (seconds) => {
            if (audio && !isNaN(seconds)) {
                const newTime = audio.currentTime - seconds;
                audio.currentTime = Math.max(newTime, 0);
            }
        };

        const getCurrentTime = () => {
            if (audio) {
                return audio.currentTime;
            }
            return 0;
        };

        const setAudioRepeat = () => {
            if (isRepeating) {
                audio.addEventListener('ended', repeatHandler, { once: true });
            } else {
                audio.removeEventListener('ended', repeatHandler);
            }
        };

        const repeatHandler = () => {
            console.log("Audio ended, repeating...");
            play(currentRecord);
        };

        const repeat = () => {
            isRepeating = !isRepeating;
            setAudioRepeat();
        };

        return { play, pause, resume, stop, fastForward, fastBackward, getCurrentTime, repeat };
    })();

    const handleAudio = (record) => {
        setRecord(record);
        setModalShow(true);
    }

    const handleDownloadAudio = (record, event) => {
        event.stopPropagation();  // Prevents the audio playing event from firing

        console.log(record)

        const proxyUrl = `/api/v1/agents/play_recording/${record.call_sid}`;
        axios({
            method: 'get',
            url: proxyUrl,
            responseType: 'blob'
        })
            .then(response => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `recording_${record.call_sid}.mp3`); // creates a download attribute with filename
                document.body.appendChild(link);
                link.click();

                // Clean up and revoke the object URL
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Download error:', error);
            });
    }


    return (
        <Page title="Recording" height={'100%'} >
            {
                recordings.length ?
                    <Paper
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            border: '1px solid rgba(65,65,65, 0.13)',
                            borderRadius: '10px',
                            p: 3,
                            color: 'text.primary'
                        }}
                    >
                        <TableContainer sx={{ maxHeight: 800 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {headerSections.map((column) => (
                                            <TableCell
                                                key={column}
                                                align={column.align}
                                            >
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recordings.length ?
                                        recordings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover key={row.companyName + index} onClick={e => handleAudio(row)}>
                                                        {sections.map((column, index1) => {
                                                            const value = row[column];
                                                            return (
                                                                <TableCell key={column + index1} align={column.align}>
                                                                    {column === 'date_created' ?
                                                                        new Date(value).toLocaleString('en-GB', {
                                                                            day: '2-digit',
                                                                            month: 'short',
                                                                            year: 'numeric',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        }) :
                                                                        (
                                                                            column === 'duration' ?
                                                                                getDuration(Number(value)) :
                                                                                value
                                                                        )

                                                                    }

                                                                </TableCell>
                                                            );
                                                        })}
                                                        <IconButton
                                                            aria-label="download"
                                                            onClick={(e) => handleDownloadAudio(row, e)}
                                                        >
                                                            <Download />
                                                        </IconButton>
                                                    </TableRow>
                                                );
                                            }) : ''
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={recordings.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper> :
                    <RecordingsEmpty />
            }
            <AudioPlayerModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                record={record}
                audioController={audioController}
            />
        </Page>
    )
}