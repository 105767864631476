import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import axios from 'src/utils/axios';
import AddToKnowledgeBaseModal from 'src/components/modals/knowledge-base/AddToKnowledgeBaseModal';
import SubKnowledgeBaseCard from 'src/components/cards/SubKnowledgeBaseCard';

export default function EditKnowledgeBase() {
    const { id } = useParams();
    const [knowledgeBase, setKnowledgeBase] = React.useState({
        id: id
    });

    const [subBases, setSubBases] = React.useState([]);

    const [addToKnowledgeBaseModalShow, setAddToKnowledgeBaseModalShow] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            axios
                .get('/api/v1/knowledgebases/single/' + id)
                .then(result => {
                    setKnowledgeBase(result.data[0]);
                });

            axios
                .get('/api/v1/knowledgebases/sub/get/' + id)
                .then(result => {
                    setSubBases([...result.data]);
                });
        }
    }, [id]);


    return (
        <Container sx={{ mt: 5 }}>
            <Typography variant='h3'>
                {knowledgeBase.name}
            </Typography>
            <Grid container spacing={1} pt={3}>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    height: "200px",
                    border: "1px dashed",
                    borderRadius: "5%",
                    flexDirection: "column",
                  }}
                  onClick={(e) => setAddToKnowledgeBaseModalShow(true)}
                >
                  <FileUploadOutlinedIcon sx={{ fontSize: "40px" }} />
                  <Typography>Add To Knowledge Base</Typography>
                </Button>
              </Grid>
      
              {subBases.map((item) => (
                <Grid item xs={12} sm={4} md={3}>
                  <SubKnowledgeBaseCard
                    subBases={subBases}
                    setSubBases={setSubBases}
                    data={item}
                  />
                </Grid>
              ))}
            </Grid>

            <AddToKnowledgeBaseModal
                addToKnowledgeBaseModalShow={addToKnowledgeBaseModalShow}
                setAddToKnowledgeBaseModalShow={setAddToKnowledgeBaseModalShow}
                knowledgeBaseId={knowledgeBase.id}
                subBases={subBases}
                setSubBases={setSubBases}
            />
        </Container >
    )
}