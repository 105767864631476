import * as React from "react";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme, useMediaQuery } from "@mui/material";

export default function SuccessChart({ data }) {
  const [isHidden, setIsHidden] = React.useState(false);
  const [series, setSeries] = React.useState([
    {
      data: [
        { id: 0, value: 10, label: "success" },
        { id: 1, value: 15, label: "failed" },
      ],
    },
  ]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  React.useEffect(() => {
    if (data.length) {
      setSeries([{ data: data }]);
    }
  }, [data]);

  return (
    <Stack>
      <FormControlLabel
        checked={isHidden}
        control={
          <Checkbox onChange={(event) => setIsHidden(event.target.checked)} />
        }
        label="hide the legend"
        labelPlacement="end"
      />
      <PieChart
        series={series}
        slotProps={{ legend: { hidden: isHidden } }}
        width={isSmallScreen ? 300 : isMediumScreen ? 400 : 500}
        height={isSmallScreen ? 150 : isMediumScreen ? 200 : 250}
      />
    </Stack>
  );
}
